import React from "react";
import PostApiCall from "./Api";
import Notiflix from "notiflix";
import moment from "moment";
import { store } from "./context/store";
import BestSelling from "./BestSelling";
import Slider from "react-slick";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import { Drawer } from "antd";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronRight />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronLeft />
    </div>
  );
}

class Searchbar extends React.Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      suggestions: [],
      text: "",
      SearchText: "",
      featureProducts: []
    };
  }

  componentDidMount() {
    if (this.state.suggestions.length == 0) {
      PostApiCall.postRequest(
        {
          variantName: "",
        },
        "SearchVariantByProduct"
      ).then((results) =>
        // const objs = JSON.parse(result._bodyText)
        results.json().then((obj1) => {
          if (results.status == 200 || results.status == 201) {
            this.setState({
              suggestions: obj1.data,
            });
            // this.renderSuggestions()
          }
        })
      );
    }
    PostApiCall.postRequest({ sectionname: "Featured Products" }, "GetWebsiteHomePageItems").then((resultdes) =>
      resultdes.json().then((obj) => {
        // console.log(obj.data);
        this.setState({
          featureProducts: obj.data,
          Loading: false
        });

      })
    );
  }

  changeMenu = () => {
    this.setState({ showMenu: true });
  };

  hideMenu = () => {
    this.setState({ showMenu: false });
  };

  onTextChange = (e) => {
    this.setState({
      SearchText: e.target.value,
    });
  };
  selectedText(value) {
    this.setState(() => ({
      text: value,
      suggestions: [],
    }));
  }

  render() {
    const productslider = {
      dots: false,
      infinite: true,
      autoplay: true,
      arrows: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      // responsive: [
      //   {
      //     breakpoint: 1200,
      //     settings: {
      //       slidesToShow: 2,
      //       slidesToScroll: 1,
      //     },
      //   },
      //   {
      //     breakpoint: 600,
      //     settings: {
      //       slidesToShow: 1,
      //       slidesToScroll: 2,
      //       initialSlide: 1,
      //       infinite: true,
      //     },
      //   },
      //   {
      //     breakpoint: 480,
      //     settings: {
      //       slidesToShow: 2,
      //       slidesToScroll: 2,
      //       infinite: true,
      //       dots: false,
      //     },
      //   },
      // ],
    };
    const { text, suggestions } = this.state;
    const filteredSuggestions = suggestions.filter(
      (data) =>
        data.fld_variantname
          .toLowerCase()
          .includes(this.state.SearchText.toLowerCase()) &&
        this.state.SearchText !== ""
    );

    return (
      <div style={{ display: "inline" }}>
        <div className="mobile-menu-icon">
          <i
            className="fa fa-search"
            aria-hidden="true"
            onClick={this.changeMenu}
          ></i>
        </div>
        <Drawer
          title={``}
          placement="right"
          onClose={this.hideMenu}
          open={this.state.showMenu}
          className="mobile-searchbar"
        >
          <div className="mobile-container">
            <div className="search-bar">
              <div className="d-flex">
                <input
                  type="text"
                  onChange={this.onTextChange}
                  value={this.state.SearchText}
                  placeholder="Search here"
                ></input>
              </div>
              <div
                className="card search-result"
                style={{
                  display: this.state.SearchText == "" ? "none" : "block",
                }}
                onMouseLeave={() => {
                  this.setState({
                    SearchText: "",
                  });
                }}
              >
                <div
                  // className="card-body "
                  style={{
                    display: this.state.SearchText == "" ? "none" : "block",
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      SearchText: "",
                    });
                  }}
                >
                  <ul className="list-group list-group-flush">
                    {
                      filteredSuggestions
                        .filter((data, index) => {
                          if (
                            data.fld_variantname
                              .toLowerCase()
                              .includes(this.state.SearchText.toLowerCase()) &&
                            this.state.SearchText !== ""
                          ) {
                            return data;
                          }
                        })
                        .map((data, index) => {
                          return (
                            <>
                              <li className="list-group-item">
                                <a
                                  href={`${process.env.PUBLIC_URL}/productdetail/${data.fld_variantname?.replace(
                                    /\s/g,
                                    "-"
                                  )}/${data.fld_variantid}`}
                                  className="searchProduct"
                                >
                                  <img src={data.fld_imageurl} />
                                  <h5>
                                    <span className="Search_variantName">
                                      {data.fld_variantname}{" "}
                                    </span>
                                  </h5>
                                </a>
                              </li>
                            </>
                          );
                        })}
                    {this.state.SearchText !== "" && filteredSuggestions.length === 0 && (
                      <p className="text-center mt-5">The product you are searching for is not available.</p>
                    )}
                  </ul>
                </div>
              </div>
              {this.state.SearchText === "" && (
                <p className="text-center mt-5">Search, Select, Savor: Your Culinary Journey Starts Here!</p>
              )}
            </div>

            <div className="mobile-contact-options mb-end">
              <div className="footerfollowus">
                <div className="row">
                  <div className="col-md-12">
                    <div className="section-title">
                      <h4 className="section-title-category-section">Featured Products</h4>
                      <div className="title-divider"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 arrow-section product-card-section searchbar-offcanvas">
                    <Slider {...productslider}>
                      {this.state.featureProducts?.filter((dat) => {
                        if (dat.fld_website_discount_price > 1) {
                          return dat
                        }
                      }).map((item) => {
                        return <BestSelling itemdata={item} />;
                      })}
                    </Slider>
                  </div>
                </div>
                {/* <p style={{ color: "333", marginRight: "15px" }}>
                  {" "}
                  Got question ? Call us 24/7 on:{" "}
                </p>
                <a
                  href={`tel:${this.context.clientData[0].mobile}`}
                  className="headerContact footer-margin"
                  style={{
                    color: "#000",
                    marginRight: "18px",
                    marginTop: "0px",
                  }}
                >
                  {this.context.clientData[0].mobile}
                </a>


                <p style={{ color: "#333" }}>Follow us on:</p>

                <ul
                  className="social-icons footer-margin"
                  style={{ marginLeft: "15px", marginTop: "1px" }}
                >
                  {this.context.clientData[0].facebook ?
                    <li>
                      <a
                        href={this.context.clientData[0].facebook}
                        target="_blank"
                      >
                        <i
                          className="fa fa-facebook-square social-icon"
                          style={{ color: "#1877f2" }}
                        ></i>
                      </a>
                    </li>
                    : ""}
                  {this.context.clientData[0].linkedin ?
                    <li>
                      <a
                        href={this.context.clientData[0].linkedin}
                        target="_blank"
                      >
                        <i
                          className="fa fa-linkedin-square social-icon"
                          aria-hidden="true"
                          style={{ color: "#0a66c2" }}
                        ></i>
                      </a>
                    </li>
                    : ""}
                  {this.context.clientData[0].twitter ?
                    <li>
                      <a href={this.context.clientData[0].twitter} target="_blank">
                        <i
                          className="fa fa-twitter-square social-icon"
                          style={{ color: "rgb(29, 155, 240)" }}
                        ></i>
                      </a>
                    </li>
                    : ""}
                  {this.context.clientData[0].instagram ?
                    <li>
                      <a href={this.context.clientData[0].instagram} target="_blank">
                        <i
                          className="fa fa-instagram social-icon"
                          style={{ color: "#cd486b" }}
                        ></i>
                      </a>
                    </li>
                    : ""}
                </ul> */}
              </div>
            </div>
          </div>

        </Drawer>
      </div>
    );
  }
}

export default Searchbar;
