import { Dropdown, Skeleton, Space } from "antd";
import moment from "moment";
import Notiflix from "notiflix";
import React from "react";
import { FaAngleDown, FaCartPlus, FaShoppingCart } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { MdLocationPin } from "react-icons/md";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PostApiCall from "./Api";
import ChangePasswordModal from "./ChangePasswordModal";
import { store } from "./context/store";
import GetApiCall from "./GetApi";
import LoginModal from "./LoginModal";
import PincodeModal from "./PincodeModal/PincodeModal";
import "./Search.css";

class Header extends React.Component {
  listener = null;
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      nav: false,
      suggestions: [], // data when search eneabled
      text: "",
      ProductCart: [],
      WishlistData: [],
      SearchText: null,
      Items: [],
      LoginData: [],
      MenuCategory: false,
      ProductAmount: 0,
      Name: null,
      MyAccount: false,
      ClientSettings: [],
      Logo: "",
      currentPin: localStorage.getItem("Pincode") || "",
      ShowModal: window.location.pathname == "/" && (localStorage.getItem("Pincode") === null || localStorage.getItem("Pincode") === "") ? true : false,
      ChangePasswordModal: false,
    };
  }

  componentDidMount() {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
    });

    window.scrollTo(0, 0);

    GetApiCall.getRequest("GetSiteSettingsV1").then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj1.data)
          this.context.setLogo(JSON.parse(obj1.data[0].logo).logo[0].url);
          this.context.setClientData(obj1.data);
          this.setState({
            ClientSettings: obj1.data,
            Logo: JSON.parse(obj1.data[0].logo).logo[0].url,
          });
        }
      })
    );

    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    if (logindetails != null) {
      this.setState({
        LoginData: logindetails[0],
        Name: logindetails[0].fld_name,
      });
      this.context.setLoggedIn(true)
    }

    if (localStorage.getItem("CustomerData") == null) {
      GetApiCall.getRequest("generateToken").then((resultdes) => {
        if (resultdes.status == 200 || resultdes.status == 201) {
          resultdes.json().then((obj) => {
            localStorage.setItem("access", JSON.stringify(obj.token));
          });
        }
        PostApiCall.postRequest(
          {
            catCount: "*",
          },
          "GetProductVertical"
        ).then((results) =>
          // const objs = JSON.parse(result._bodyText)
          results.json().then((obj1) => {
            if (results.status == 200 || results.status == 201) {
              // console.log(obj1.data)
              this.setState({
                Items: obj1.data,
              });
            }
          })
        );

        window.addEventListener("scroll", this.handleScroll);

        this.getCartdetails();

        if (this.state.suggestions.length == 0) {
          PostApiCall.postRequest(
            {
              variantName: "",
            },
            "SearchVariantByProduct"
          ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj1) => {
              if (results.status == 200 || results.status == 201) {
                //   console.log(obj1.data);

                this.setState({
                  suggestions: obj1.data
                });

                // this.renderSuggestions()
              }
            })
          );
        }
      });
    } else {
      PostApiCall.postRequest(
        {
          catCount: "*",
        },
        "GetProductVertical"
      ).then((results) =>
        // const objs = JSON.parse(result._bodyText)
        results.json().then((obj1) => {
          if (results.status == 200 || results.status == 201) {
            // console.log(obj1.data)
            this.setState({
              Items: obj1.data,
            });
          }
        })
      );

      window.addEventListener("scroll", this.handleScroll);

      this.getCartdetails();

      if (this.state.suggestions.length == 0) {
        PostApiCall.postRequest(
          {
            variantName: "",
          },
          "SearchVariantByProduct"
        ).then((results) =>
          results.json().then((obj1) => {
            if (results.status == 200 || results.status == 201) {
              this.setState({
                suggestions: obj1.data,
              });
              // this.renderSuggestions()
            }
          })
        );
      }
    }
  }

  getCartdetails = () => {
    // Notiflix.Loading.Circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    // console.log('cart')
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          // Notiflix.Loading.Remove();
          //console.log(res1.data)

          this.setState({
            ProductCart: res1.data,
          });

          this.context.setcart(res1.data.length);
          if (res1.data.length == 0) {
            localStorage.removeItem("OrderData");
          } else {
            this.setState({
              ProductAmount: res1.data[0].fld_cart_amount_no_shipping,
            });
            this.context.setcartamount(res1.data[0].fld_cart_amount_preview);
          }
        }
      });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.pageYOffset > 0) {
      if (!this.state.nav) {
        this.setState({ nav: true });
      }
    } else {
      if (this.state.nav) {
        this.setState({ nav: false });
      }
    }
  };

  onTextChange = (e) => {
    this.setState({
      SearchText: e.target.value,
    });
  };

  // toggle pincode modal
  showPincodeModal = () => {
    // localStorage.setItem("Modalstatus", true);
    this.setState({
      ShowModal: true,
    });
  };
  closePincodeModal = () => {
    this.setState({
      ShowModal: false,
    });
  };
  showChangePasswordModal = () => {
    this.setState({
      ChangePasswordModal: true,
    });
  };
  closeChangePasswordModal = () => {
    this.setState({
      ChangePasswordModal: false,
    });
  };

  handleToggleModal = (updatedVal) => {
    this.setState({
      openModal: updatedVal,
    })
  }

  render() {
    const { text, suggestions } = this.state;

    // if (this.state.ShowModal === true) {
    //   <PincodeModal />
    // }

    const items = this.state.Items.map((product, i) => ({
      key: i + 1,
      label: (
        <div
          className="category-wrapper-header"
          onClick={() => {
            sessionStorage.removeItem("SelectedCategory");
          }}>
          <a
            className="dropdown-item"
            data-category_id="0"
            href={`/ct/${product.selected_cat_name
              ?.toLowerCase()
              .replace(/\s/g, "-").replace('/', "-")}/${product.fld_verticleid}/${product?.selected_cat_id
              }`}>
            <img src={product.fld_image} className="category-image-header" />
            <b>{product.fld_name}</b>
          </a>
        </div>
      ),
    }));

    return (
      <div>
        <LoginModal isopen={this.state.openModal} handleToggleModal={this.handleToggleModal} />
        {!this.state.nav && (
          <div className="Advertisement-header px-5">
            <p>Elevate Your Culinary Experience with Our Gourmet Varieties .</p>
          </div>
        )}
        <header className="">
          <div className={`Nav ${this.state.nav && "Nav__black shadow-sm"}`}>
            <div className="container-fluid nav-container">
              <div className="nav ">
                <div className="col-md-12">
                  <div className="row justify-content-between">
                    <div className="col-md-1 d-flex align-items-center">
                      <div className="logo1 header">
                        {this.state.Logo ? (
                          <a href="/">
                            {" "}
                            <img
                              src={this.state.Logo}
                              className="logo"
                              alt="logo"
                              height="230px"
                              width="227px"></img>
                          </a>
                        ) : (
                          <Skeleton.Node className="web-logo-skeleton" />
                        )}
                      </div>
                    </div>
                    <div className="col-md-2 d-flex align-items-center justify-content-end pr-lg-0 nav-pincode-box">
                      <span onClick={this.showPincodeModal}>
                        <MdLocationPin />{" "}
                        {this.state.currentPin || this.context.pincode ? this.state.currentPin || this.context.pincode : "Select Pincode"}
                      </span>
                    </div>
                    <div className="col-md-8">
                      <div className="row justify-content-between align-items-center">
                        <div className="search-wrapper">
                          <div id="search" className="d-flex">
                            <div className="search-input-group flex-fill">
                              <div className="search-input d-flex ">
                                <div className="dropdown search-category">
                                  <Dropdown
                                    menu={{
                                      items,
                                    }}
                                    placement="bottomLeft">
                                    <a onClick={(e) => e.preventDefault()}>
                                      <Space>
                                        Shop by Category
                                        <FaAngleDown />
                                      </Space>
                                    </a>
                                  </Dropdown>
                                  {/* <button
                                    type="button"
                                    className="btn_custom dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                    onMouseOver={() => {
                                      this.setState({
                                        MenuCategory: true,
                                      });
                                    }}
                                    onMouseLeave={() => {
                                      this.setState({
                                        SearchText: "",
                                      });
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        MenuCategory: !this.state.MenuCategory,
                                      });
                                    }}
                                    style={{
                                      color: "#212529",
                                      fontWeight: "300",
                                    }}
                                  >
                                    Shop by Category{" "}
                                    <i
                                      className="arrow down"
                                      style={{
                                        color: "#999",
                                        fontWeight: "300",
                                      }}
                                    ></i>
                                  </button> */}
                                  {/* <div
                                    className="dropdown-menu dropdown-menu-left"
                                    x-placement="bottom-start"
                                    onMouseLeave={() => {
                                      this.setState({
                                        MenuCategory: false,
                                      });
                                    }}
                                    style={{
                                      position: "absolute",
                                      willChange: "transform",
                                      top: "0px",
                                      left: "0px",
                                      transform: "translate3d(0px, 26px, 0px)",
                                      display:
                                        this.state.MenuCategory == true
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    {this.state.Items.map((product, i) => (
                                      <div className="category-wrapper-header" onClick={() => { sessionStorage.removeItem("SelectedCategory") }}>
                                        <a className="dropdown-item"
                                          data-category_id="0"
                                          href={`/ct/${product.selected_cat_name?.toLowerCase()
                                            .replace(/\s/g, "-")}/${product.fld_verticleid}/${product?.selected_cat_id}`}
                                        >
                                          <img src={product.fld_image} className="category-image-header" />
                                          <p>{product.fld_name}</p>
                                        </a>
                                      </div>
                                    ))}
                                  </div> */}
                                </div>
                                <div
                                  className="flex-fill"
                                  aria-autocomplete="off">
                                  <input
                                    type="text"
                                    name="search"
                                    onChange={this.onTextChange}
                                    value={this.state.SearchText}
                                    // data-autocomplete="5"
                                    // data-autocomplete_route="extension/maza/product/product/autocomplete"
                                    placeholder="Search Products"
                                    // data-autoComplete="off"
                                    autoComplete="false"
                                  // aria-autocomplete="list"
                                  />
                                </div>
                              </div>
                              <div
                                className="card search-result"
                                style={{
                                  display:
                                    this.state.SearchText == null
                                      ? "none"
                                      : "block",
                                }}
                                onMouseLeave={() => {
                                  this.setState({
                                    SearchText: null,
                                  });
                                }}>
                                <div className="card-body ">
                                  <ul className="list-group list-group-flush">
                                    {suggestions
                                      .filter((data, index) => {
                                        if (
                                          data.fld_variantname
                                            .toLowerCase()
                                            .includes(
                                              this.state.SearchText?.toLowerCase()
                                            ) &&
                                          this.state.SearchText !== null
                                        ) {
                                          return data;
                                        }
                                      })
                                      .map((data, index) => {
                                        return (
                                          <li className="list-group-item">
                                            <a
                                              href={`${process.env.PUBLIC_URL
                                                }/productdetail/${data.fld_variantname
                                                  .toLowerCase()
                                                  .replace(
                                                    /[^a-zA-Z0-9]/g,
                                                    "-"
                                                  )}/${data.fld_variantid}`}>
                                              <img
                                                src={data.fld_imageurl}
                                                alt={data.fld_variantname.replace(
                                                  /\s/g,
                                                  "-"
                                                )}
                                                width="50"
                                                height="50"
                                              />
                                            </a>
                                            <a
                                              href={`${process.env.PUBLIC_URL
                                                }/productdetail/${data.fld_variantname
                                                  .toLowerCase()
                                                  .replace(
                                                    /[^a-zA-Z0-9]/g,
                                                    "-"
                                                  )}/${data.fld_variantid}`}
                                              className="searchProduct">
                                              <p className="d-flex flex-column justify-content-center">
                                                <span className="Search_categoryname">
                                                  {data.fld_categoryname}
                                                </span>
                                                <span className="Search_variantName">
                                                  {data.fld_variantname}{" "}
                                                </span>
                                              </p>
                                            </a>
                                            {data.fld_selling_price ? (
                                              <p className="searchPriceinfo">
                                                ₹{" "}
                                                {parseFloat(
                                                  data.fld_selling_price
                                                ).toFixed(2)}{" "}
                                                &nbsp;{" "}

                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            {/* {data.fld_stock !=
                                              "Out of Stock" ? ( */}
                                            <div
                                              className="thm-btn search-btn"
                                              onClick={() => {
                                                // Notiflix.Loading.Circle();
                                                var login =
                                                  localStorage.getItem(
                                                    "CustomerData"
                                                  );
                                                var logindetails =
                                                  JSON.parse(login);

                                                var Order =
                                                  localStorage.getItem(
                                                    "OrderData"
                                                  );
                                                var Orderdetails =
                                                  JSON.parse(Order);
                                                if (login == null) {
                                                  localStorage.setItem(
                                                    "itemdetails",
                                                    JSON.stringify([
                                                      {
                                                        Item: `${data.fld_variantid}`,
                                                        Quantity: `${1}`,
                                                      },
                                                    ])
                                                  );
                                                  // window.location.href =
                                                  //   "/login";
                                                  this.setState({
                                                    openModal: true
                                                  })
                                                } else {
                                                  PostApiCall.postRequest(
                                                    {
                                                      orderdate:
                                                        moment().format("lll"),
                                                      itemdetails: `
                                              [
                                              {
                                                  "Item":${data.fld_variantid},
                                                  "Quantity":${1}
                                              }
                                              ]
                                              `,
                                                      status: "INCART",
                                                      customerid:
                                                        localStorage.getItem(
                                                          "CustomerData"
                                                        ) == null
                                                          ? null
                                                          : logindetails[0]
                                                            .fld_customerid,
                                                      createdon:
                                                        moment().format("lll"),
                                                      updatedon:
                                                        moment().format("lll"),
                                                      orderid:
                                                        localStorage.getItem(
                                                          "OrderData"
                                                        ) == null
                                                          ? 0
                                                          : Orderdetails[0]
                                                            .order_id,

                                                      updatedby:
                                                        localStorage.getItem(
                                                          "CustomerData"
                                                        ) == null
                                                          ? null
                                                          : logindetails[0]
                                                            .fld_userid,
                                                    },
                                                    "AddToCartWebsite"
                                                  ).then((result) =>
                                                    result
                                                      .json()
                                                      .then((obj) => {
                                                        if (
                                                          result.status ==
                                                          200 ||
                                                          result.status == 201
                                                        ) {
                                                          Notiflix.Loading.Remove();
                                                          Notiflix.Notify.Success(
                                                            "Product added to Cart."
                                                          );
                                                          if (
                                                            localStorage.getItem(
                                                              "OrderData"
                                                            ) == null
                                                          ) {
                                                            localStorage.setItem(
                                                              "OrderData",
                                                              JSON.stringify(
                                                                obj.data
                                                              )
                                                            );
                                                            this.getCartdetails();
                                                          }
                                                          this.getCartdetails();
                                                        } else {
                                                          Notiflix.Loading.Remove();
                                                          Notiflix.Notify.Failure(
                                                            obj.data
                                                          );
                                                        }
                                                      })
                                                  );
                                                }
                                              }}>
                                              <FaCartPlus />
                                            </div>
                                            {/* ) : (
                                              <a
                                                className="thm-btn search-btn"
                                                style={{
                                                  background: "#a8aba8",
                                                }}
                                              >
                                                Sold Out
                                              </a>
                                            )}  */}
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div>
                              <button
                                type="submit"
                                style={{
                                  border: "0px",
                                  outline: "none",
                                  backgroundColor: "white",
                                  fontSize: "1.2rem",
                                  color: "gray",
                                  marginRight: "10px",
                                }}>
                                <i className="fa fa-search icon"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1">
                      {this.context.clientData[0]?.storeStatus == "Active" &&
                        <div className="dropdown useraccountlist">
                          <div className="my-account mr-4">
                            <ul className="account-options">
                              <li>
                                <a href="/cart" className="shoppingBag">
                                  <span className="wishlist-number">
                                    {this.context.cart}
                                  </span>
                                  <FaShoppingCart />
                                </a>
                              </li>
                            </ul>
                          </div>
                          <a
                            href={
                              this.state.LoginData.length == 0
                                ? "/login"
                                : "/customerprofile"
                            }
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-expanded="false"
                            onMouseOver={() => {
                              this.setState({ MyAccount: true });
                            }}>
                            <IoPerson />
                          </a>

                          {this.context.loggedIn === false ? (
                            <div
                              className="dropdown-menu dropdown-menu-left"
                              x-placement="bottom-start"
                              onMouseLeave={() => {
                                this.setState({
                                  MyAccount: false,
                                });
                              }}
                              style={{
                                display:
                                  this.state.MyAccount == true ? "block" : "none",
                              }}>
                              <a
                                className="dropdown-item login"
                                data-category_id="0"
                                href="/login">
                                Login
                              </a>
                              <a
                                className="dropdown-item register"
                                data-category_id="0"
                                href="/register">
                                Sign up
                              </a>
                            </div>
                          ) : (
                            <div
                              className="dropdown-menu dropdown-menu-left"
                              x-placement="bottom-start"
                              onMouseLeave={() => {
                                this.setState({
                                  MyAccount: false,
                                });
                              }}
                              style={{
                                display:
                                  this.state.MyAccount == true ? "block" : "none",
                              }}>
                              <a
                                className="dropdown-item login"
                                data-category_id="0"
                                href="/customerprofile">
                                Hi, {this.state.Name ? this.state.Name?.substr(0, 7) + "..." : 'User'}
                              </a>
                              <a
                                style={{ cursor: "pointer" }}
                                className="dropdown-item register"
                                data-category_id="0"
                                onClick={() => {
                                  this.showChangePasswordModal();
                                }}>
                                Change Password
                              </a>
                              <a
                                style={{ cursor: "pointer" }}
                                className="dropdown-item register"
                                data-category_id="0"
                                onClick={() => {
                                  localStorage.removeItem("CustomerData");
                                  localStorage.removeItem("access");
                                  localStorage.removeItem("Pincode")
                                  window.location.href = "/";
                                }}>
                                Logout
                              </a>
                            </div>
                          )}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* <DropDown /> */}
        <Modal
          isOpen={this.state.ChangePasswordModal}
          centered
          className="pincode-modal">
          <ModalHeader
            className="pt-0 pr-3 pb-0"
            toggle={this.closeChangePasswordModal}></ModalHeader>
          <ModalBody className="px-3 pt-0">

            <ChangePasswordModal />
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.ShowModal} centered className="pincode-modal">
          <ModalHeader
            className="pt-0 pr-3 pb-0"
            toggle={this.closePincodeModal}></ModalHeader>
          <ModalBody className="px-3 pt-0">
            <PincodeModal showModal={this.closePincodeModal} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default Header;
