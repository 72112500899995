import { createContext, useState } from "react";

export const store = createContext()

const Provider = ({
    children
}) => {
    const [cart, setcart] = useState(0);
    const [cartamount, setcartamount] = useState(0);
    const [banner, setBanner] = useState([]);
    const [logo, setLogo] = useState("");
    const [clientData, setClientData] = useState([]);
    const [showPincodeModal, setShowPincodeModal] = useState(false);
    const [pincode, setPincode] = useState(null);
    const [currentPage, setCurrentPage] = useState("Home");
    const [loggedIn, setLoggedIn] = useState(false);
    return (
        <store.Provider value={{
            cart, setcart, cartamount, setcartamount, currentPage, setCurrentPage, banner, setBanner, logo, setLogo,
            showPincodeModal, setShowPincodeModal,
            pincode, setPincode,
            clientData, setClientData,
            loggedIn, setLoggedIn
        }}>
            {children}
        </store.Provider>
    )
}

export default Provider;