import Notiflix from "notiflix";
import React from "react";
import PostApiCall from "./Api";
import { store } from "./context/store";
import GetApiCall from "./GetApi";
import Mobilemenu from "./Mobilemenu";
import Searchbar from "./Searchbar";
import { Skeleton } from "antd";
import { MdLocationPin } from "react-icons/md";
import { Button, Drawer, Radio, Space } from "antd";
import { IoClose, IoPerson } from "react-icons/io5";
import LoginModal from "./LoginModal";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ChangePasswordModal from "./ChangePasswordModal";
import PincodeModal from "./PincodeModal/PincodeModal";
import maleAvatar from "./assets/img/avatar-icons/male-avatar.png"
import femaleAvatar from "./assets/img/avatar-icons/female-avatar.png"
class Headermobile extends React.Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      ProductCart: [],
      ClientSettings: [],
      Logo: "",
      openDrawer: false,
      ShowModal: false,
      LoginData: [],
      openModal: false,
      Name: null,
      pincodeModal: window.location.pathname == "/" && (localStorage.getItem("Pincode") === null || localStorage.getItem("Pincode") === "") ? true : false,
      ChangePasswordModal: false,
    };
  }
  showPincodeModal = () => {
    this.setState({
      pincodeModal: true,
    });
  };
  closePincodeModal = () => {
    this.setState({
      pincodeModal: false,
    });
  };
  showChangePasswordModal = () => {
    this.setState({
      ChangePasswordModal: true,
    });
  };
  closeChangePasswordModal = () => {
    this.setState({
      ChangePasswordModal: false,
    });
  };

  handleToggleModal = (updatedVal) => {
    this.setState({
      openModal: updatedVal,
    })
  }
  componentDidMount() {
    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
    });

    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    if (logindetails != null) {
      this.setState({
        LoginData: logindetails[0],
        Name: logindetails[0].fld_name,
      });
    }

    GetApiCall.getRequest("GetSiteSettingsV1").then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj1.data)
          this.context.setLogo(JSON.parse(obj1.data[0].logo).logo[0].url);
          this.context.setClientData(obj1.data);
          this.setState({
            ClientSettings: obj1.data,
            Logo: JSON.parse(obj1.data[0].logo).logo[0].url,
          });
        }
      })
    );

    if (localStorage.getItem("CustomerData") == null) {
      GetApiCall.getRequest("generateToken").then((resultdes) => {
        if (resultdes.status == 200 || resultdes.status == 201) {
          resultdes.json().then((obj) => {
            localStorage.setItem("access", JSON.stringify(obj.token));
          });
        }
      });
    } else {
      this.getCartdetails();
    }
  }

  getCartdetails = () => {
    // Notiflix.Loading.Circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    // console.log('cart')
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          // Notiflix.Loading.Remove();
          // console.log(res1.data)

          this.setState({
            ProductCart: res1.data,
          });
          this.context.setcart(res1.data.length);
        }
      });
  };
  onCloseDrawer = () => {
    this.setState({ openDrawer: false });
  };
  render() {
    const showDrawer = () => {
      this.setState({ openDrawer: true });
    };
    const onChangeDrawer = (e) => {
      this.setState({ placement: e.target.value });
    };
    return (
      <div>
        {/* <BrowserView>
          <Header />
        </BrowserView>
        <MobileView> */}
        <LoginModal
          isopen={this.state.openModal}
          handleToggleModal={this.handleToggleModal}
        />
        <div className="header-mobile">
          <div className="container-fluid">
            <div className="row d-flex align-items-center">
              {/* <div className="col-md-4 col-2">
                <div>
                  <Mobilemenu></Mobilemenu>
                </div>
              </div> */}
              <div className="col-md-4 col-12 text-center py-2">
                <div className="logo1 text-center ml-0">
                  {this.state.Logo ? (
                    <a href="/">
                      {" "}
                      <img
                        src={this.state.Logo}
                        className="logo img-fluid img-responsive"
                        alt="logo"></img>
                    </a>
                  ) : (
                    <Skeleton.Node className="Mobile-logo-skeleton" />
                  )}
                </div>
              </div>
              {/* <div className="col-md-4 col-3">
                <ul className="account-options">
                  <li>
                    <Searchbar></Searchbar>
                  </li>
                  <li>
                    <a href="/cart">
                      <i className="fa fa-shopping-bag" aria-hidden="true"></i>
                      <span className="wishlist-number">{this.context.cart}</span>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
        <div className="fixed-moblie-nav row">
          <div className="col-3">
            <div>
              <Mobilemenu></Mobilemenu>
            </div>
          </div>
          <div className="col-9 d-flex align-items-center">
            <ul className="account-options d-flex align-items-center justify-content-between w-100">
              <li>
                <Searchbar></Searchbar>
              </li>
              <li>
                <a href="/cart">
                  <i className="fa fa-shopping-bag" aria-hidden="true"></i>
                  <span className="wishlist-number">{this.context.cart}</span>
                </a>
              </li>
              <li>
                {this.state.LoginData.length == 0 ? (
                  <>
                    <IoPerson
                      onClick={() => {
                        this.setState({
                          openModal: true,
                        });
                      }}
                      className="cursor-pointer"
                    />
                  </>
                ) : (
                  <>
                    <IoPerson onClick={showDrawer} className="cursor-pointer" />
                  </>
                )}
                <Drawer
                  // title="Basic Drawer"
                  placement={"bottom"}
                  closable={false}
                  onClose={this.onCloseDrawer}
                  open={this.state.openDrawer}
                  key={"bottom"}
                  className="customer-drawer-main position-relative">
                  <IoClose
                    onClick={this.onCloseDrawer}
                    className="customer-drawer-close-btn"
                  />
                  <div className="">
                    <div>
                      <div className="d-flex profile-box mb-4">
                        <div className="profile-box-icon">
                        <img src={maleAvatar} className="img-fluid w-100 h-100" />
                        </div>
                        <div>
                          <h5>Hi, {this.state.Name}</h5>
                          <a
                            className="dropdown-item login mt-3"
                            data-category_id="0"
                            href="/customerprofile">
                            View Profile
                          </a>
                        </div>
                      </div>
                    </div>
                    <a
                      style={{ cursor: "pointer" }}
                      className="dropdown-item register"
                      data-category_id="0"
                      onClick={() => {
                        this.showChangePasswordModal();
                      }}>
                      Change Password
                    </a>
                    <a
                      style={{ cursor: "pointer" }}
                      className="dropdown-item register"
                      data-category_id="0"
                      onClick={() => {
                        localStorage.removeItem("CustomerData");
                        localStorage.removeItem("access");
                        localStorage.removeItem("Pincode");
                        window.location.href = "/";
                      }}>
                      Logout
                    </a>
                  </div>
                </Drawer>
              </li>
            </ul>
          </div>
        </div>
        <div className="mobile-pincode-icon" onClick={this.showPincodeModal}>
          <MdLocationPin />
        </div>
        {/* </MobileView> */}

        <Modal
          isOpen={this.state.ChangePasswordModal}
          centered
          className="pincode-modal">
          <ModalHeader
            className="pt-0 pr-3 pb-0"
            toggle={this.closeChangePasswordModal}></ModalHeader>
          <ModalBody className="px-3 pt-0">
            <ChangePasswordModal />
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.pincodeModal} centered className="pincode-modal">
          <ModalHeader
            className="pt-0 pr-3 pb-0"
            toggle={this.closePincodeModal}></ModalHeader>
          <ModalBody className="px-3 pt-0">
            <PincodeModal showModal={this.closePincodeModal} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default Headermobile;
