import React, { Component } from "react";
import { Card, CardBody, CardTitle, Progress } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Notiflix from "notiflix";
import GetApiCall from "../GetApi";
import BestSelling from "../BestSelling";
import PostApiCall from "../Api";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronRight />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronLeft />
    </div>
  );
}
class Frequently extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 50,
      featureProducts: [],
    };
  }

  componentDidMount() {
    PostApiCall.postRequest(
      { sectionname: "Featured Products" },
      "GetWebsiteHomePageItems"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        // console.log(obj.data);
        this.setState({
          featureProducts: obj.data,
          Loading: false,
        });
      })
    );
  }

  render() {
    const productslider = {
      dots: false,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 1,
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
      ],
    };
    const productsliderdemo = {
      dots: false,
      infinite: false,
      autoplay: true,
      arrows: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1224,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false,
          },
        },
      ],
    };
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle className="section-title mt-1 mb-4">
              <h4 className="section-title-category-section">
                Frequently Bought Items
              </h4>
              <div className="title-divider"></div>
            </CardTitle>

            <div className="row" style={{ marginRight: "-4px" }}>
              <div className="arrow-section product-card-section col-12">
                <Slider {...productsliderdemo}>
                  {this.state.featureProducts.filter((dat) => {
                    if (dat.fld_website_discount_price > 1) {
                      return dat
                    }
                  }).map((item) => (
                    <BestSelling itemdata={item} />
                  ))}
                </Slider>
              </div>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default Frequently;
