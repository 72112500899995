import React, { useContext } from "react";
import "./ClientSupport.css";
import WhatsappSupport from "../../assets/img/ClientSupport/WhatsappSupport.png";
// import PhoneSupport from "../../assets/img/ClientSupport/PhoneSupport.png";
import { isMobile } from "react-device-detect";
import { store } from "../../context/store";

const ClientSupport = () => {
  const { clientData } = useContext(store)
  return (
    <>
      {/* <div className="pulse1"></div> */}
      {/* <div className="pulse2"></div> */}
      {/* <div
        className="whatsapp-icon"
        style={{
          bottom: isMobile ? "180px" : "150px",
        }}
      >
        <a href={`https://wa.me/${clientData[0]?.mobile}`} target="_blank">
          {" "}
          <img
            style={{
              width: "50px",
              objectFit: "contain",
              marginTop: "10px",
              //   transform: "scaleX(-1)",
            }}
            src={WhatsappSupport}
          />
          <div className="pulse-bg p-3 position-absolute rounded-circle"></div>
          <div className="pulse-bg-2 p-3 position-absolute rounded-circle"></div>
        </a>
      </div> */}
      {/* <div className="call-icon">
        <a href="tel:+917299048725" target="_blank">
          <img
            style={{
              width: "50px",
              objectFit: "contain",
              marginTop: "10px",
              transform: "scaleX(-1)",
            }}
            src={PhoneSupport}
          />
          <div className="pulse-bg-blue p-3 position-absolute rounded-circle"></div>
          <div className="pulse-bg-2-blue p-3 position-absolute rounded-circle"></div>
        </a>
      </div> */}
    </>
  );
};

export default ClientSupport;
