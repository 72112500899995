import { Select } from "antd";
import React from "react";
import { MdLocationPin } from "react-icons/md";
import PostApiCall from "../Api";
import "./PincodeModal.css";
import { store } from "../context/store";

class PincodeModal extends React.Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
      suggestions: [],
      SearchText: "",
      selectedValue: null,
    };
  }
  componentDidMount() {
    if (this.state.suggestions.length == 0) {
      PostApiCall.postRequest(
        {
          WhereClause: "",
        },
        "GetWarehousePincodeMapping"
      ).then((results) =>
        // const objs = JSON.parse(result._bodyText)
        results.json().then((obj1) => {
          if (results.status == 200 || results.status == 201) {
            //   console.log(obj1.data);

            this.setState({
              suggestions: obj1.data,
            });
            // this.renderSuggestions()
          }
        })
      );
    }
  }
  onTextChange = (e) => {
    this.setState({
      SearchText: e.target.value,
    });
  };

  render() {
    const { text, suggestions } = this.state;
    const { Option } = Select;
    return (
      <>
        {/* <Button color="danger" onClick={toggle}>
        Click Me
      </Button> */}

        <div className="d-flex justify-content-center text-center align-items-center flex-column">
          <MdLocationPin className="location-icon" />
          <div className="section-title mb-0 pb-2 mt-0">
            <h4 className="section-title-category-section mb-3 mb-md-0">
              Select a location for <br className="d-block d-md-none" /> delivery
            </h4>
          </div>
          <p className="mt-3 mt-0">
            Please provide your delivery location to see products at nearby
            store
          </p>
        </div>
        <div>
          <Select
            showSearch
            placeholder="Search and select delivery pincode"
            optionFilterProp="children"
            value={this.state.selectedValue}
            onChange={(value) => {
              this.context.setPincode(value);
              localStorage.setItem("Pincode", value);
              localStorage.setItem("Modalstatus", false);
              this.setState({
                modal: false,
                selectedValue: value,
              });
              this.props.showModal()
            }}
            filterOption={(input, option) =>
              option?.label.toLowerCase().includes(input)
            }
            className="w-100 mt-4">
            {suggestions.map((data) => {
              return (
                <Option value={data.Fld_pincode} label={data.label}>
                  {data.label}
                </Option>
              );
            })}
          </Select>
          {/* 
          <Select
            showSearch
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.label.toLowerCase().includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            onChange={(value) => {
              localStorage.setItem("Pincode", value);
              localStorage.setItem("Modalstatus", false)
              this.setState({
                modal: false,
                selectedValue: value
              })
            }}
            options={
              suggestions.map((data) => {
                return (
                  <Option value={data.Fld_pincode} label={data.label} >
                    {data.label}
                  </Option>
                )
              })
            }
          /> */}
          {/* <input
                type="text"
                name="Enter Pincode"
                onChange={this.onTextChange}
                value={this.state.SearchText}
                // data-autocomplete="5"
                // data-autocomplete_route="extension/maza/product/product/autocomplete"
                placeholder="Search Products"
                // data-autoComplete="off"
                autoComplete="false"
              // aria-autocomplete="list"
              /> */}
        </div>
        <div
          className="card search-result"
          style={{
            display: this.state.SearchText == "" ? "none" : "block",
          }}
          onMouseLeave={() => {
            this.setState({
              SearchText: "",
            });
          }}>
          <div className="card-body ">
            <ul className="list-group list-group-flush">
              {suggestions
                .filter((data, index) => {
                  if (
                    data.label
                      .toLowerCase()
                      .includes(this.state.SearchText.toLowerCase()) &&
                    this.state.SearchText !== ""
                  ) {
                    return data;
                  }
                })
                .map((data, index) => {
                  return (
                    <li className="list-group-item">
                      <a
                        className="thm-btn search-btn"
                        onClick={() => {
                          localStorage.setItem("Pincode", data.label);
                        }}>
                        <p className="searchPriceinfo">{data.label}</p>
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        {/* <ModalFooter>
           
            <button type="submit" className="btn btn-common">
              Proceed
            </button>
          </ModalFooter> */}
      </>
    );
  }
}
export default PincodeModal;
