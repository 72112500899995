import { Drawer } from "antd";
import React from "react";
import Collapsible from "react-collapsible";
import PostApiCall from "./Api";
import { store } from "./context/store";

class Mobilemenu extends React.Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      Items: [],
      ProductCart: [],
      LoginData: [],
      ProductAmount: 0,
    };
  }

  componentDidMount() {
    // Notiflix.Loading.Init({
    //   svgColor: "#0f3d74",
    // });

    var loginDetails = JSON.parse(localStorage.getItem("CustomerData"));

    if (loginDetails != null) {
      this.setState({
        LoginData: loginDetails,
      });
    }

    // Notiflix.Loading.Circle();
    PostApiCall.postRequest(
      {
        catCount: "*",
      },
      "GetProductVertical"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            Items: obj1.data,
          });
        }
      })
    );
    this.getCartdetails();
  }

  getCartdetails = () => {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    // console.log('cart')
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          // Notiflix.Loading.Remove();

          // console.log(res1.data)
          this.setState({
            ProductCart: res1.data,
          });
          if (res1.data.length == 0) {
            localStorage.removeItem("OrderData");
          } else {
            this.setState({
              ProductAmount: res1.data[0].fld_cart_amount,
            });
          }
        }
      });
  };

  changeMenu = () => {
    this.setState({ showMenu: true });
  };

  hideMenu = () => {
    this.setState({ showMenu: false });
  };

  render() {
    return (
      <div style={{ display: "inline" }}>
        <div className="mobile-menu-icon menu-bars">
          <i
            className="fa fa-bars "
            aria-hidden="true"
            onClick={this.changeMenu}
          ></i>
        </div>
        <Drawer
          title={``}
          placement="left"
          onClose={this.hideMenu}
          open={this.state.showMenu}
          className="mobile-drawer"
        >
          <div className="mobile-container h-100 d-flex flex-column justify-content-between">
            <ul className="menu-options">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <div style={{ position: "relative" }}>
                  <span
                    style={{
                      color: "#221f1f",
                      fontWeight: "300",
                      fontSize: "32px",
                      position: "absolute",
                      right: "0",
                      top: "-15px",
                      zIndex: "0"
                    }}>+</span>
                  <Collapsible trigger="Shop by Category">

                    {/* :
                      <span
                        style={{
                          color: "#221f1f",
                          fontWeight: "300",
                          fontSize: "32px",
                          position: "absolute",
                          right: "0",
                          top: "-15px"
                        }}>-</span> */}
                    <ul className="mobile-menu-categories">
                      {this.state.Items.map((product, i) => {
                        return (
                          <li className="mobile-menu-categories-list">
                            <a
                              href={`/ct/${product.selected_cat_name?.toLowerCase()?.replace(/\s/g, "-").replace('/', "-")}/${product.fld_verticleid}/${product.selected_cat_id}`}
                            >
                              <img src={product.fld_image} />

                              <p>{product.fld_name}</p>
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  </Collapsible>
                </div>
              </li>
              {/* {this.state.LoginData.length == 0 ? (
                <li>
                  <a href="/login">Login</a>
                </li>
              ) : (
                <li>
                  <a href="/customerprofile">My Account</a>
                </li>
              )} */}

              {/* <li
                style={{
                  display: this.state.LoginData.length == 0 ? "block" : "none",
                }}
              >
                <a href="/register">Register</a>
              </li> */}

              <li>
                <a href="/about_us">About Us</a>
              </li>
              <li>
                <a href="/contact-us">Contact Us</a>
              </li>
              <li
                style={{
                  display: this.state.LoginData.length == 0 ? "none" : "block",
                }}
              >
                <a
                  onClick={() => {
                    localStorage.removeItem("CustomerData");
                    localStorage.removeItem("access");
                    window.location.href = "/";
                  }}
                >
                  Logout
                </a>
              </li>
            </ul>
            <div className="mobile-contact-options border-top">
              <div className="footerfollowus">
                <p style={{ color: "333", marginRight: "15px" }}>
                  {" "}
                  Got question ? Call us 24/7 on:{" "}
                </p>
                <a
                  href={`tel:${this.context.clientData[0]?.mobile}`}
                  className="headerContact footer-margin"
                  style={{
                    color: "#221f1f",
                    marginRight: "18px",
                    marginTop: "0px",
                    fontWeight: "400",
                    fontSize: "27px",
                  }}
                >
                  {this.context.clientData[0]?.mobile}
                </a>
                <p style={{ color: "#333" }}>Follow us on:</p>

                <div
                  className="social-icons footer-margin mt-2"
                  style={{
                    marginLeft: "15px",
                    marginTop: "15px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="mt-2"
                    style={{
                      display: "flex",
                      width: "100%",
                      // justifyContent: "space-between",
                    }}
                  >
                    {this.context.clientData[0]?.facebook ?
                      <a
                        href={this.context.clientData[0]?.facebook}
                        target="_blank"
                      >
                        <i
                          className="fa fa-facebook social-icon"
                        // style={{ color: "#1877f2" }}
                        ></i>
                      </a>
                      : ""}
                    {this.context.clientData[0]?.instagram ?
                      <a
                        href={this.context.clientData[0]?.instagram}
                        target="_blank"
                      >
                        <i
                          className="fa fa-instagram social-icon"
                        // style={{ color: "#cd486b" }}
                        ></i>
                      </a>
                      : ""}
                    {this.context.clientData[0]?.linkedin ?
                      <a
                        href={this.context.clientData[0]?.linkedin}
                        target="_blank"
                      >
                        <i
                          className="fa fa-linkedin social-icon"
                        // style={{ color: "rgb(29, 155, 240)" }}
                        ></i>
                      </a>
                      : ""}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    {this.context.clientData[0]?.twitter ?
                      <a href={this.context.clientData[0]?.twitter} target="_blank">
                        <i
                          className="fa fa-twitter social-icon"
                        // style={{ color: "#1877f2" }}
                        ></i>
                      </a>
                      : ""}
                    {this.context.clientData[0]?.pintrest ?
                      <a href={this.context.clientData[0]?.pintrest} target="_blank">
                        <i
                          className="fa fa-pinterest social-icon"
                        // style={{ color: "#FF0000" }}
                        ></i>
                      </a>
                      : ""}
                    {this.context.clientData[0]?.youtube ?
                      <a
                        href={this.context.clientData[0]?.youtube}
                        target="_blank"
                      >
                        <i
                          className="fa fa-youtube social-icon"
                        // style={{ color: "#FF0000" }}
                        ></i>
                      </a>
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
        {/* {menuOverlay} */}
        {/* {menu} */}
      </div>
    );
  }
}

export default Mobilemenu;
