import Notiflix from "notiflix";
import React, { Component } from "react";
import { Card, CardBody, Container } from "reactstrap";
import PostApiCall from "../Api";
import GetApiCall from "../GetApi";
// import Header from "../Webheader";


export class AddressBookForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: null,
      Title: null,
      Mobile: null,
      Area: null,
      FlatNo: null,
      Landmark: null,
      CountryData: [],
      CityData: [],
      StateData: [],
      CountryId: 0,
      StateId: 0,
      CityId: 0,
      Country: null,
      State: null,
      City: null,
      Pincode: null,
      AddressId: null,
      NumRegex: /^[0-9]*$/,
      GSTNumber: null,
      otherTitle: ""
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });
    navigator.geolocation.getCurrentPosition((position) => {
      var Latitude = position.coords.latitude;
      var Longitude = position.coords.longitude;
      this.setState({
        Latitude: Latitude,
        Longitude: Longitude,
      });

      // Notiflix.Loading.Remove();

      //console.log("Latitude is :", Latitude);
      //console.log("Longitude is :", Longitude);
    });

    GetApiCall.getRequest("GetCountry").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          CountryData: obj.data,
        });

        if (obj.data.length != 0) {
          // this.props.setcountry(obj.data[100].label)

          this.setState({
            CountryId: 101,
            Country: obj.data[102].label,
          });
        }
        PostApiCall.postRequest(
          {
            countryid: 101,
          },
          "GetState"
        ).then((results) =>
          // const objs = JSON.parse(result._bodyText)
          results.json().then((objstate) => {
            if (results.status == 200 || results.status == 201) {
              if (objstate.data.length != 0) {
                // this.props.setstate()

                this.setState({
                  // StateId: objstate.data[0].value,
                  StateId: 4035,

                  StateData: objstate.data,
                  State: objstate.data[0].label,
                });
              }

              // console.log(objstate.data[0].value)
              PostApiCall.postRequest(
                {
                  stateid: 4035,
                  // stateid: objstate.data[0].value,
                },
                "GetCity"
              ).then((resultscity) =>
                // const objs = JSON.parse(result._bodyText)
                resultscity.json().then((objcity) => {
                  if (resultscity.status == 200 || resultscity.status == 201) {
                    // console.log(objcity.data[0].value)
                    if (objcity.data.length != 0) {
                      // this.props.setcity(objcity.data[0].label)
                      this.setState({
                        CityId: 131517,
                        // CityId: objcity.data[0].value,
                        CityData: objcity.data,
                        City: objcity.data[0].label,
                      });
                    }
                  }
                })
              );
            }
          })
        );
      })
    );
  }
  onChangeCountry(country) {
    // console.log(typeof(country.target.value));

    //  console.log(this.state.CountryData[parseInt(country.target.value) +1])
    this.setState({
      CountryId: country.target.value,
      Country: this.state.CountryData[parseInt(country.target.value) + 1].label,
    });
    //  this.props.setcountry(this.state.CountryData[country.target.value - 1].label);

    Notiflix.Loading.Circle();

    PostApiCall.postRequest(
      {
        countryid: country.target.value,
        Country:
          this.state.CountryData[parseInt(country.target.value) + 1].label,
      },
      "GetState"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          //  this.props.setstate(obj.data[0].label)
          // console.log(obj);
          this.setState({
            StateId: obj.data[0].value,
            State: obj.data[0].label,
          });

          PostApiCall.postRequest(
            {
              stateid: obj.data[0].value,
            },
            "GetCity"
          ).then((resultscity) =>
            resultscity.json().then((objcity) => {
              if (resultscity.status == 200 || resultscity.status == 201) {
                this.setState({
                  CityData: objcity.data,
                  StateData: obj.data,
                });
                if (objcity.data.length > 0) {
                  // this.props.setcity(objcity.data[0].label)

                  this.setState({
                    CityId: objcity.data[0].value,
                    City: objcity.data[0].label,
                  });
                }
                Notiflix.Loading.Remove();
              }
            })
          );
        }
      })
    );
  }

  onChangeState(state) {
    //  this.props.setstate(state.target.value)
    this.setState({
      StateId: state.target.value,
    });

    Notiflix.Loading.Circle();

    for (var i = 0; i < Object.keys(this.state.StateData).length; i++) {
      if (this.state.StateData[i].value == state.target.value) {
        // this.props.setstate();
        this.setState({
          State: this.state.StateData[i].label,
        });
      }
    }

    PostApiCall.postRequest(
      {
        stateid: state.target.value,
      },
      "GetCity"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length > 0) {
            // this.props.setcity(obj.data[0].label)
            this.setState({
              CityData: obj.data,
              CityId: obj.data[0].value,
              City: obj.data[0].label,
            });
          }
          Notiflix.Loading.Remove();
          // this.props.cityData(obj.data)
        }
      })
    );
  }

  onChangeCity(city) {
    // this.props.setcity(city.target.value)
    this.setState({
      CityId: city.target.value,
    });

    for (var i = 0; i < Object.keys(this.state.CityData).length; i++) {
      if (this.state.CityData[i].value == city.target.value) {
        this.setState({
          City: this.state.CityData[i].label,
        });
        // this.props.setcity(this.state.CityData[i].label);
      }
    }
  }

  onSaveData() {
    if (this.state.Title != null) {
      if (this.state.Name != null) {
        if (this.state.Mobile != null) {
          if (this.state.Mobile.length == 10) {
            if (this.state.FlatNo != null) {
              if (this.state.Area != null) {
                if (this.state.Country != null) {
                  if (this.state.State != null) {
                    if (this.state.City != null) {
                      if (this.state.Pincode != null) {
                        if (this.state.Pincode.length == 6) {
                          this.onPost();
                        } else {
                          Notiflix.Notify.Failure("Please enter valid pincode");
                        }
                      } else {
                        Notiflix.Notify.Failure("Please enter pincode");
                      }
                    } else {
                      Notiflix.Notify.Failure("Please enter city");
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter state");
                  }
                } else {
                  Notiflix.Notify.Failure("Please select country");
                }
              } else {
                Notiflix.Notify.Failure(
                  "Please enter Area/Colony/Street/Sector/Village"
                );
              }
            } else {
              Notiflix.Notify.Failure(
                "Please enter Flat/House/Company/Building/Apartment No."
              );
            }
          } else {
            Notiflix.Notify.Failure("Please enter valid mobile number");
          }
        } else {
          Notiflix.Notify.Failure("Please enter mobile number");
        }
      } else {
        Notiflix.Notify.Failure("Please enter your Full Name");
      }
    } else {
      Notiflix.Notify.Failure("Please select Address Title");
    }
  }

  onPost() {
    Notiflix.Loading.Circle();

    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    PostApiCall.postRequest(
      {
        CUSTOMERID: logindetails[0].fld_customerid,
        addressid: null,
        title: this.state.Title == "Others" ? this.state.otherTitle : this.state.Title,
        name: this.state.Name,
        mobileNo: this.state.Mobile,
        area: this.state.Area,
        flatno: this.state.FlatNo,
        landmark: this.state.Landmark,
        gstNumber: this.state.GSTNumber,
        country: this.state.CountryId,
        state: this.state.StateId,
        city: this.state.CityId,
        pincode: this.state.Pincode,
        action: "INSERT",
        longitude: this.state.Longitude,
        latitude: this.state.Latitude,
      },
      "AddCustomerAddress"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Address Successfully Added");
          localStorage.getItem("SelectAddress") == null ? window.location.href = "/customerprofile" : window.location.href = "/cart"

        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  }

  render() {
    return (
      <div>
        {/* <Header></Header> */}
        <Container
          fluid>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <Card className="mt-0">
                  <CardBody className="pt-0">
                    <div className="section-title mt-0">
                      <h4 className="section-title-category-section mt-0">
                        Add Address
                      </h4>
                      <div className="title-divider"></div>
                    </div>
                    <form>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-md-12 center">
                            <div className="form-group">
                              <label for="validationCustom01">
                                Address Title
                                <span className="mandatory">*</span>
                              </label>
                              <br />
                              <label
                                className="radio-inline"
                                style={{ marginLeft: "10px" }}>
                                <input
                                  type="radio"
                                  name="optradio"
                                  checked={
                                    this.state.Title === "Home" ? true : false
                                  }
                                  onChange={() => {
                                    this.setState({
                                      Title: "Home",
                                    });
                                  }}
                                />{" "}
                                Home
                              </label>
                              <label
                                className="radio-inline"
                                style={{ marginLeft: "10px" }}>
                                <input
                                  type="radio"
                                  name="optradio"
                                  checked={
                                    this.state.Title === "Office"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    this.setState({
                                      Title: "Office",
                                    });
                                  }}
                                />{" "}
                                Work
                              </label>
                              <label className="radio-inline ml-2">
                                <input
                                  type="radio"
                                  name="optradio"
                                  checked={
                                    this.state.Title === "Others"
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    this.setState({
                                      Title: "Others",
                                    });
                                  }}
                                />{" "}
                                Others
                              </label>
                            </div>
                            {this.state.Title == "Others" ?
                              <div className="form-group mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={this.state.otherTitle}
                                  onChange={(text) => {
                                    this.setState({
                                      otherTitle: text.target.value,
                                    });
                                  }}
                                />
                              </div>
                              : ""}
                          </div>
                          <div className="col-12">
                            <div className="form-group mb-3">
                              <label for="validationCustom01">
                                Full Name<span className="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.Name}
                                onChange={(text) => {
                                  this.setState({
                                    Name: text.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group mb-3">
                              <label for="validationCustom01">
                                Mobile Number
                                <span className="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                id="CategoryName"
                                className="form-control"
                                value={this.state.Mobile}
                                onChange={(mobile) => {
                                  if (
                                    this.state.NumRegex.test(
                                      mobile.target.value
                                    ) &&
                                    mobile.target.value.length <= 10
                                  ) {
                                    this.setState({
                                      Mobile: mobile.target.value,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 md-12">
                            <div className="form-group mb-3">
                              <label for="validationCustom01">
                                Flat/House/Building No.
                                <span className="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.FlatNo}
                                onChange={(text) => {
                                  this.setState({
                                    FlatNo: text.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 md-12">
                            <div className="form-group mb-3">
                              <label for="validationCustom01">
                                Area/Street/Sector/Village
                                <span className="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.Area}
                                onChange={(text) => {
                                  this.setState({
                                    Area: text.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 md-12">
                            <div className="form-group mb-3">
                              <label for="validationCustom01">
                                Landmark (optional)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.Landmark}
                                onChange={(text) => {
                                  this.setState({
                                    Landmark: text.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 md-12">
                            <div className="form-group mb-3">
                              <label for="validationCustom01">
                                GST Number (optional)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.GSTNumber}
                                onChange={(text) => {
                                  this.setState({
                                    GSTNumber: text.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label for="validationCustom01">
                                Country<span className="mandatory">*</span>
                              </label>
                              <select
                                className="custom-select my-1 mr-sm-2"
                                id="inlineFormCustomSelectPref"
                                name="UserType"
                                value={this.state.CountryId}
                                onChange={this.onChangeCountry.bind(this)}>
                                {this.state.CountryData.map((schedule) => (
                                  <option
                                    key={schedule.label}
                                    value={schedule.value}>
                                    {schedule.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label for="validationCustom01">
                                State<span className="mandatory">*</span>
                              </label>
                              <select
                                className="custom-select my-1 mr-sm-2"
                                id="inlineFormCustomSelectPref"
                                name="UserType"
                                value={this.state.StateId}
                                onChange={this.onChangeState.bind(this)}>
                                {this.state.StateData.map((schedule) => (
                                  <option
                                    key={schedule.label}
                                    value={schedule.value}>
                                    {schedule.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label for="validationCustom01">
                                City<span className="mandatory">*</span>
                              </label>
                              <select
                                className="custom-select my-1 mr-sm-2"
                                id="inlineFormCustomSelectPref"
                                name="UserType"
                                value={this.state.CityId}
                                onChange={this.onChangeCity.bind(this)}>
                                {this.state.CityData.map((schedule) => (
                                  <option
                                    key={schedule.label}
                                    value={schedule.value}>
                                    {schedule.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label for="validationCustom01">
                                Pincode<span className="mandatory">*</span>
                              </label>
                              <input
                                type="text"
                                id="CategoryName"
                                className="form-control my-1 mr-sm-2"
                                value={this.state.Pincode}
                                onChange={(mobile) => {
                                  if (
                                    this.state.NumRegex.test(
                                      mobile.target.value
                                    ) &&
                                    mobile.target.value.length <= 6
                                  ) {
                                    this.setState({
                                      Pincode: mobile.target.value,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <button
                              type="button"
                              className="btn align-items-center place-order w-auto"
                              onClick={this.onSaveData.bind(this)}>
                              Save Address {"  "}{" "}
                              {/* <i
                                  className="fa fa-bookmark"
                                  aria-hidden="true"></i> */}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default AddressBookForm;
