import { Component } from "react"
import Header from "../Header";
import Footer from "../Footer";
import "./ProductReviews.css"
import Container from "./Container"
import Notiflix from "notiflix";
import PostApiCall from "../Api";
// import WebsiteReviewBanner from "../assets/img/ReviewBanner/WebsiteReviewBanner.png"
// import ReviewBanner from "../assets/img/ReviewBanner/ReviewBanner.png"
// import { isMobile } from "react-device-detect";



class WriteAReview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            VariantImage: null,
            VariantName: null,
            VariantId: null
        }
    }

    componentDidMount() {
        const queryString = window.location.search;

        // Parse the query string using URLSearchParams
        const params = new URLSearchParams(queryString);

        // Extract values by key
        const paramValue = params.get('reviewid');
        // console.log(window.location.pathname.split('/')[2]);
        if (paramValue) {
            // Do something with the query string value
            // console.log('Query string value:', paramValue);
        }
        Notiflix.Loading.Init({
            svgColor: "#0f3d74",
        });

        Notiflix.Loading.Circle();


        PostApiCall.postRequest(
            {
                id: window.location.pathname.split('/')[2],
            },
            "GetProductByVariant"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {

                    this.setState({
                        ProductDetail: obj.data,

                    });
                    Notiflix.Loading.Remove();
                    for (var i = 0; i < Object.keys(obj.data).length; i++) {
                        if (obj.data[i].fld_variantid == window.location.pathname.split('/')[2]) {
                            this.setState({
                                SelectedVariant: obj.data[i],
                                VariantName: obj.data[i].fld_variantname,
                                VariantImage: obj.data[i].VariantImage,
                                VariantId: obj.data[i].fld_variantid
                            });
                        }
                    }
                }
            })
        );
    }

    render() {
        return (
            <>
                <Header />
                <section className="inner-banner">
                    <div className="main-banner mobileinner-banner">
                        {/* <img
                            src={isMobile ? ReviewBanner : WebsiteReviewBanner}
                            alt="inner-banner"
                            className="img-responsive"
                        /> */}
                    </div>
                </section>
                <Container variantName={this.state.VariantName} variantImage={this.state.VariantImage} variantId={this.state.VariantId} />
                <Footer />
            </>
        )
    }
}

export default WriteAReview