import { Component } from "react";
import VerifiedIcon from "../assets/img/VerifiedIcon.svg";
import "./ProductReviews.css";
import Header from "../Header";
import Footer from "../Footer";
import PostApiCall from "../Api";
import moment from "moment";
import CategoryListing from "../CategoryListing";
import { isMobile } from "react-device-detect";

class AllReviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ReviewData: [],
      ProductDetail: [],
      Loading: true,
      AverageRating: null,
    };
  }

  componentDidMount() {
    PostApiCall.postRequest(
      {
        whereClause: `where fld_variant_id=${this.props.match.params.variantid} order by fld_review_id desc`,
        recordCount: "*",
      },
      "GetReviews"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.data.length != 0) {
            this.setState({
              ReviewData: obj1.data,
              AverageRating: obj1.data[0].avg_rating,
              Loading: false,
            });
          } else {
            this.setState({
              ReviewData: obj1.data,
              Loading: false,
            });
          }
        }
      })
    );

    PostApiCall.postRequest(
      {
        id: parseInt(this.props.match.params.variantid),
      },
      "GetProductByVariant"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj.data)

          this.setState({
            ProductDetail: obj.data,
          });
          for (var i = 0; i < Object.keys(obj.data).length; i++) {
            if (obj.data[i].fld_id == this.props.match.params.varid) {
              this.setState({
                SelectedVariant: obj.data[i],
                VariantName: obj.data[i].fld_variantname,
                VariantImage: obj.data[i].VariantImage,
              });
            }
          }
        }
      })
    );
  }

  render() {
    return (
      <>
        <Header />
        <section
          className="mt-5 pt-4 pt-lg-0 mt-lg-3 container-fluid all-review-section">
          <div className="row">
          <div className="col-md-12">
                    <div className="section-title mt-0">
                      <h4 className="section-title-category-section mt-0">
                        All Reviews
                      </h4>
                    </div>
                  </div>
            <div className="col-lg-9 order-2 order-lg-1 container-fluid all-review-scroll">
              <div className="row">
                <div
                  className="col-md-4"
                  style={{
                    background: "#fff!important",
                    display:
                      this.state.ReviewData.length == 0 ? "none" : "block",
                  }}>
                  <div className="overall-ratings">
                    <div className="ratings">
                      <strong className="ratings-exact">
                        {this.state.AverageRating}
                      </strong>
                      <span className="ratings-upto">/5</span>
                    </div>
                    <div className="ratings-verified">
                      <strong className="ratings-static">Overall Rating</strong>
                      <span className="verified-upto">
                        {this.state.ReviewData.length} verified ratings
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-8">
                  <div>
                    {/* <div className="writereview">Write a review !</div> */}
                    <button
                      className="btn align-items-center place-order mt-3"
                      onClick={() => {
                        localStorage.setItem("ReviewPage", "ReviewPage");
                        var login = localStorage.getItem("CustomerData");
                        var logindetails = JSON.parse(login);
                        if (logindetails) {
                          window.location.href = `/writereview/${this.props.match.params.variantid}`;
                        } else {
                          window.location.href = "/login";
                        }
                      }}>
                      WRITE REVIEW
                    </button>
                  </div>
                </div>
              </div>
              {this.state.Loading == true ? (
                <div className="rating-spinner">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="row">
                    <div
                      className="col-md-12 customer-reviews">
                      {/* Most Useful Reviews */}
                    </div>
                  </div>
                  <div>
                      <div className="row">
                    {this.state.ReviewData.map((data, i) => {
                      return (
                      
                          <div className="col-md-6">
                                {/* <div className="col-md-4" style={{ background: "#fff!important" }}>
                                                            <div className="userInfoSection">
                                                                <img src="https://images-static.nykaa.com/prod-review/default_profile_image.png" alt="avatar" className="userImageIcon" />
                                                                <div className="UserInformationProductReviews">
                                                                    <span className="userNameReviews">{data.fld_name}</span>
                                                                    <span className="verified-buyers-icon">
                                                                        <img src={VerifiedIcon} height="23%" width="23%"></img>Verified Buyers</span>
                                                                </div>
                                                            </div>
                                                        </div> */}
                            <div className="review-card m-0">
                              <div className="star-ratings">
                                <section className="css-rwwnk2">
                                  {data.fld_rating <= 2 ? (
                                    <span className="css-1at2way">
                                      {data.fld_rating}
                                      <i className="css-w0v4um">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="13"
                                          height="14">
                                          <title>star</title>
                                          <path
                                            fill="#FFF"
                                            d="M2.555 11.878c-.05 0-.1-.025-.15-.05a.277.277 0 01-.1-.275l1.098-4.092L.109 4.79c-.1-.05-.125-.175-.1-.275a.24.24 0 01.225-.174l4.242-.225L5.998.15c.05-.075.15-.15.25-.15s.2.075.225.15l1.522 3.967 4.242.225c.1 0 .2.075.225.174.025.1 0 .2-.075.275L9.093 7.46l1.098 4.092c.025.1 0 .2-.1.275-.075.05-.2.075-.274 0L6.248 9.532l-3.569 2.296c-.05.05-.075.05-.124.05z"></path>
                                        </svg>
                                      </i>
                                    </span>
                                  ) : (
                                    <span className="css-1at2wjx">
                                      {data.fld_rating}
                                      <i className="css-w0v4um">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="13"
                                          height="14">
                                          <title>star</title>
                                          <path
                                            fill="#FFF"
                                            d="M2.555 11.878c-.05 0-.1-.025-.15-.05a.277.277 0 01-.1-.275l1.098-4.092L.109 4.79c-.1-.05-.125-.175-.1-.275a.24.24 0 01.225-.174l4.242-.225L5.998.15c.05-.075.15-.15.25-.15s.2.075.225.15l1.522 3.967 4.242.225c.1 0 .2.075.225.174.025.1 0 .2-.075.275L9.093 7.46l1.098 4.092c.025.1 0 .2-.1.275-.075.05-.2.075-.274 0L6.248 9.532l-3.569 2.296c-.05.05-.075.05-.124.05z"></path>
                                        </svg>
                                      </i>
                                    </span>
                                  )}
                                </section>
                                <div className="css-177e1lt">
                                  {moment(data.fld_review_date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </div>
                              </div>
                              <section className="css-1v6g5ho">
                                <h4 className="css-tm4hnq">
                                  {data.fld_headline}
                                </h4>
                                <p className="css-1n0nrdk">
                                  {data.fld_review_description}
                                </p>
                              </section>
                            </div>
                          </div>
                      );
                    })}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="col-lg-3 order-1 order-lg-2"
            //   style={{ display: isMobile ? "none" : "block" }}
              >
              {this.state.ProductDetail.map((data, i) => {
                return <CategoryListing itemdata={data} />;
              })}
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default AllReviews;
