import React from "react";
import react, { Component } from "react";
import Notiflix from "notiflix";
import PostApiCall from "./Api";
import moment from "moment";
import { store } from "./context/store";
import NoImage from "./assets/img/NoImage.png";
import { isMobile } from "react-device-detect";

class ProductList extends Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      VariantImage: null,
      VariantId: null,
      ProductId: null,
      VariantName: null,
      ItemName: null,
      VariantList: null,
      MRP: null,
      DiscountPrice: null,
      YouSave: null,
      SavePer: null,
      Stock: null,
      Weight: null,
      CategoryName: null,
    };
  }

  componentDidMount() {
    const store = this.context;
    // console.log(this.props.itemdata);
  }

  getCartdetails = () => {
    Notiflix.Loading.Circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    // console.log('cart')
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          Notiflix.Loading.Remove();

          // localStorage.setItem('CardCount', JSON.stringify(res1.data.length))

          this.setState({
            ProductCart: res1.data,
          });

          this.context.setcart(res1.data.length);
          this.context.setcartamount(res1.data[0].fld_cart_amount_preview);

          // console.log(this.context)
        }
      });
  };

  render() {
    //console.log(this.props.itemdata)

    return (
      <React.Fragment>
        <div className="item">
          <div className="product-box">
            <div className="p-images blog-images">
              <a
                href={`${process.env.PUBLIC_URL
                  }/productdetail/${(this.props.itemdata.fld_variantname.toLowerCase()).replace(
                    /[^a-zA-Z0-9]/g,
                    "-"
                  )}/${this.props.itemdata.fld_variantid}`}
              >
                <img
                  src={
                    this.props.itemdata.VariantImage == null
                      ? NoImage
                      : this.props.itemdata.VariantImage
                  }
                  alt={this.props.itemdata.fld_itemname.replace(
                    /\s/g,
                    "-"
                  )}
                ></img>
              </a>
            </div>

            <div className="content-box">
              <a
                href={`${process.env.PUBLIC_URL
                  }/productdetail/${(this.props.itemdata.fld_variantname.toLowerCase()).replace(
                    /[^a-zA-Z0-9]/g,
                    "-"
                  )}/${this.props.itemdata.fld_variantid}`}
              >
                <span style={{ color: "#00000075" }}>
                  {this.props.itemdata.fld_categoryname}
                </span>

                <p className="product-title2">
                  {this.props.itemdata.fld_variantname}
                </p>
              </a>
              {/* <a
                href={`${process.env.PUBLIC_URL
                  }/productdetail/${(this.props.itemdata.fld_variantname.toLowerCase()).replace(
                    /[^a-zA-Z0-9]/g,
                    "-"
                  )}/${this.props.itemdata.fld_variantid}`}
              >
                <h6 className="price">
                  <i className="fas fa-rupee-sign"></i> ₹{" "}
                  {parseFloat(
                    this.props.itemdata.fld_website_discount_price
                  ).toFixed(2)}
                  <span style={{ visibility: this.props.itemdata.fld_website_discount_price == this.props.itemdata.fld_mrp ? "hidden" : "visible" }}>
                    <i className="fas fa-rupee-sign"></i>{" "}
                    <s>
                      {" "}
                      ₹ {parseFloat(this.props.itemdata.fld_mrp).toFixed(2)}
                    </s>
                    <br></br>
                    <em style={{ color: "#3e3e3e" }}>
                      You Save: ₹{" "}
                      {parseFloat(this.props.itemdata.you_save_amount).toFixed(
                        2
                      )}{" "}
                      ({parseFloat(this.props.itemdata.you_save_per).toFixed(2)}
                      %)
                    </em>
                  </span>
                  <h6 className="weight" style={{ color: this.state.Stock == "Out of Stock" ? 'red' : 'green' }}>{this.state.Stock} </h6>
                </h6>
              </a> */}
            </div>
            {/* <div className="btns">
              {this.props.itemdata.available_stock === "Out Of Stock" ? (
                <a
                  className="thm-btn itm-btns"
                  style={{
                    background: "#a8aba8",
                    cursor: "default",
                    textAlign: "center",
                  }}
                >
                  Sold Out
                </a>
              ) : (
                <a
                  className="thm-btn itm-btns"
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={() => {
                    // console.log('Cart')
                    Notiflix.Loading.Circle();
                    var login = localStorage.getItem("CustomerData");
                    var logindetails = JSON.parse(login);

                    var Order = localStorage.getItem("OrderData");
                    var Orderdetails = JSON.parse(Order);
                    if (login == null) {
                      localStorage.setItem("itemdetails", JSON.stringify([
                        {
                          "Item": `${this.props.itemdata.fld_variantid}`,
                          "Quantity": `${1}`
                        }
                      ]))
                      window.location.href = "/login"
                    } else {
                      // ===========with Login=========
                      PostApiCall.postRequest(
                        {
                          orderdate: moment().format("lll"),
                          itemdetails: `
                                          [
                                          { 
                                              "Item":${this.props.itemdata
                              .fld_variantid
                            },
                                              "Quantity":${1}
                                          }
                                          ]
                                          `,
                          status: "INCART",
                          customerid:
                            localStorage.getItem("CustomerData") == null
                              ? null
                              : logindetails[0].fld_customerid,
                          createdon: moment().format("lll"),
                          updatedon: moment().format("lll"),
                          orderid:
                            localStorage.getItem("OrderData") == null
                              ? 0
                              : Orderdetails[0].order_id,
                          updatedby:
                            localStorage.getItem("CustomerData") == null
                              ? null
                              : logindetails[0].fld_userid,
                        },
                        "AddToCartWebsite"
                      ).then((result) =>
                        result.json().then((obj) => {
                          if (result.status == 200 || result.status == 201) {
                            Notiflix.Loading.Remove();
                            Notiflix.Notify.Success("Product added to Cart.");
                            // console.log(obj.data)
                            if (localStorage.getItem("OrderData") == null) {
                              //console.log('datastore')
                              localStorage.setItem(
                                "OrderData",
                                JSON.stringify(obj.data)
                              );
                              this.getCartdetails();
                            }
                            this.getCartdetails();

                            // window.location.reload();
                          } else {
                            Notiflix.Loading.Remove();
                            Notiflix.Notify.failure(obj.data);
                          }
                        })
                      );
                    }
                  }}
                >
                  <i className="bi bi-basket"></i> &nbsp; {isMobile ? 'Add' : 'Add to Cart'}
                </a>
              )}
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProductList;
