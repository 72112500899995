import React, { Component } from "react";
import Footer from "./Footer";
import Header from "./Header";
import moment from "moment";
import Notiflix from "notiflix";
import PostApiCall from "./Api";
import login from "./assets/img/banner/login.png";
import borderimg from "./assets/img/banner/border.png";
import { isMobile } from "react-device-detect";
import { AiFillEyeInvisible } from "react-icons/ai";
import { store } from "./context/store";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IoPerson } from "react-icons/io5";
import { BsFillPersonPlusFill } from "react-icons/bs";

export default class LoginModal extends Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      NumRegex: /^[0-9]*$/,
      isPasswordVisible: false,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      UrlRegex:
        /^(https:\/\/www\.|httpss:\/\/www\.|https:\/\/|httpss:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      open: false,
      EnteredOtp: "",
      MailerOtp: "",
      Email: "",
      openOtp: false,
      openPassword: false,
      Password: "",

      Salt: [],
      isNewPasswordVisible: false,
      isConPasswordVisible: false,
      Name: "",
      MobileNo: "",
      EmailAddress: "",
      RegisterPassword: "",
      ConfirmPassword: "",
      PasswordFieldType: false,
      RegisterPassword: "",
      ConfirmPassword: "",
      DobDay: 1,
      DobMonth: "January",
      DobYear: "2021",
      Month: [],
      Day: [],
      Year: [],
      Gender: "",
      GenderData: [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
        { label: "Others", value: "Others" },
      ],
      modalstatus: this.props.isopen,
      formChange: true,
    };
  }
  showFormChange = () => {
    this.setState({ formChange: false });
  };
  hideFormChange = () => {
    this.setState({ formChange: true });
  };
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });

    const store = this.context;
  }

  saveLogin() {
    if (this.state.Email != "") {
      if (
        this.state.EmailRegex.test(this.state.Email) ||
        this.state.NumRegex.test(this.state.Email)
      ) {
        if (this.state.Password != "") {
          this.onLogin();
        } else {
          Notiflix.Notify.Failure("Please enter your password.");
        }
      } else {
        Notiflix.Notify.Failure("Please enter valid email or mobile number.");
      }
    } else {
      Notiflix.Notify.Failure("Please enter your email or mobile number.");
    }
  }

  onLogin = () => {
    Notiflix.Loading.Circle();
    PostApiCall.postRequest(
      {
        email: this.state.Email,
        password: this.state.Password,
        action: "Login",
        actiondate: moment().format("lll"),
      },
      "AddwebsiteLogin"
    ).then((results) =>
      results.json().then((obj) => {
        if (
          (results.status == 200 || results.status == 201) &&
          localStorage.getItem("OrderData")
        ) {
          localStorage.setItem("CustomerData", JSON.stringify(obj.data));
          localStorage.setItem("access", JSON.stringify(obj.token));
          Notiflix.Loading.Remove();
          this.onPostMoveCart(obj);
        } else if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.Remove();
          localStorage.setItem("CustomerData", JSON.stringify(obj.data));
          localStorage.setItem("access", JSON.stringify(obj.token));
          if (localStorage.getItem("itemdetails")) {
            this.addToCart();
          }
          this.context.setLoggedIn(true)
          this.props.handleToggleModal(false)
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };

  onPostMoveCart(obj) {
    //console.log('move to cart')
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    Notiflix.Loading.Circle();

    PostApiCall.postRequest(
      {
        customerid: JSON.stringify(obj.data[0].fld_customerid),
        cartid: Orderdetails[0].order_id,
      },
      "MoveTmpCrtToMain"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          localStorage.removeItem("OrderData");
          Notiflix.Loading.Remove();
          if (localStorage.getItem("currentPage") != null) {
            localStorage.getItem("currentPage") == "Cart"
              ? (window.location.href = "/select_address")
              : (window.location.href = "/");
          } else {
            localStorage.getItem("ReviewPage") == "ReviewPage"
              ? this.props.history.goBack()
              : (window.location.href = "/");
          }
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  }
  addToCart() {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    var itemdetail = JSON.parse(localStorage.getItem("itemdetails"));
    // console.log(itemdetail)
    PostApiCall.postRequest(
      {
        orderdate: moment().format("lll"),
        itemdetails: `
                                          [
                                          { 
                                              "Item":${itemdetail[0]?.Item},
                                              "Quantity":${1}
                                          }
                                          ]
                                          `,
        status: "INCART",
        customerid:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
        createdon: moment().format("lll"),
        updatedon: moment().format("lll"),
        orderid:
          localStorage.getItem("OrderData") == null
            ? 0
            : Orderdetails[0].order_id,
        updatedby:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_userid,
      },
      "AddToCartWebsite"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Product added to Cart.");
          if (localStorage.getItem("OrderData") == null) {
            localStorage.setItem("OrderData", JSON.stringify(obj.data));
          }
          this.getCartdetails();
          localStorage.removeItem("itemdetails")
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  }
  getCartdetails = () => {
    Notiflix.Loading.Circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          Notiflix.Loading.Remove();
          this.setState({
            ProductCart: res1.data,
          });
          this.context.setcart(res1.data.length);
          this.context.setcartamount(res1.data[0].fld_cart_amount_preview);
        }
      });
  };

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });

    var day = [];
    var monthdata = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var month = [];
    var year = [];

    for (var i = 1; i <= 31; i++) {
      day.push({ label: i, value: i });
    }

    const date2 = new Date();

    for (var i = 0; i < Math.abs(date2.getUTCFullYear() - 1924); i++) {
      // console.log(date2.getUTCFullYear() - i)
      year.push({
        label: date2.getUTCFullYear() - i,
        value: date2.getUTCFullYear() - i,
      });
    }

    for (var i = 0; i < 12; i++) {
      month.push({ label: monthdata[i], value: monthdata[i] });
    }
    this.setState({
      Month: month,
      Day: day,
      Year: year,
    });
  }
  // ========RegistrationSave=========

  onChangeGender(gender) {
    this.setState({
      Gender: gender.target.value,
    });
  }

  onPostRegistration = () => {
    Notiflix.Loading.Circle();

    PostApiCall.postRequest(
      {
        name: this.state.Name,
        email: this.state.EmailAddress,
        mobile: this.state.MobileNo,
        status: "Website",
        password: this.state.RegisterPassword,
        createdon: moment().format("lll"),
        updatedon: moment().format("lll"),
        updatedby: 0,
        dob:
          this.state.DobYear +
          "-" +
          this.state.DobMonth +
          "-" +
          this.state.DobDay,
        gender: this.state.Gender,
      },
      "AddCustomer"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          // Notiflix.Notify.Success("You are Successfully Registered");

          // window.location.href = "/login";
          this.onLogin()
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };
  SaveRegistration() {
    if (this.state.Name != "") {
      if (this.state.EmailAddress != "") {
        if (this.state.EmailRegex.test(this.state.EmailAddress)) {
          if (this.state.MobileNo != "") {
            if (this.state.MobileNo.length == 10) {
              if (this.state.RegisterPassword != "") {
                this.onPostRegistration();
              } else {
                Notiflix.Notify.Failure("Please enter password.");
              }
            } else {
              Notiflix.Notify.Failure("Please enter valid mobile number.");
            }
          } else {
            Notiflix.Notify.Failure("Please enter mobile number.");
          }
        } else {
          Notiflix.Notify.Failure("Please enter valid email address.");
        }
      } else {
        Notiflix.Notify.Failure("Please enter email address.");
      }
    } else {
      Notiflix.Notify.Failure("Please enter your name.");
    }
  }

  render() {
    const { handleToggleModal } = this.props;
    return (
      <Modal isOpen={this.props.isopen} centered >
        <ModalHeader
          toggle={() => {
            handleToggleModal(false);
          }}
          className="p-0 pr-2"></ModalHeader>
        <ModalBody className="px-3 pt-0">
          {this.state.formChange && (
            <div className="login-box pb-0">
              <div className="login-modal-icon">
                <IoPerson />
              </div>
              <div className="section-title mb-0 pb-3 mt-0">
                <h4 className="section-title-category-section">
                  Get Fabulous Deals!
                </h4>
              </div>
              <p style={{ textAlign: "center", marginTop: "-10px" }}>
                Login to Order Online
              </p>

              <div style={{ marginTop: "20px" }}>
                <input
                  type="text"
                  placeholder="Use your Email ID or Phone No. to Sign in"
                  className="form-control"
                  value={this.state.email}
                  onChange={(email) => {
                    this.setState({ Email: email.target.value });
                  }}></input>
              </div>
              <div style={{ marginTop: "10px", position: "relative" }}>
                <input
                  type={this.state.PasswordFieldType ? "text" : "password"}
                  placeholder="Password"
                  className="form-control"
                  value={this.state.Password}
                  onChange={(password) => {
                    this.setState({
                      Password: password.target.value,
                    });
                  }}></input>
                <AiFillEyeInvisible
                  role={"button"}
                  onClick={() =>
                    this.setState({
                      PasswordFieldType: !this.state.PasswordFieldType,
                    })
                  }
                  style={{
                    position: "absolute",
                    right: "5%",
                    bottom: "36%",
                  }}
                />
              </div>

              <div className=" forgot-password mt-2 d-flex justify-content-end align-items-center">
                {/* <div className="d-flex align-items-center">
                  <input type="checkbox" />
                  <label className="mt-0 mb-0 ml-1 mt-1">Save Password</label>
                </div> */}
                <a
                  href="/forget-password"
                  className=" float-right forgot-pass small"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.setState({
                      Open: true,
                    });
                  }}>
                  Forgot Password?
                </a>
              </div>
              <div className="text-center ">
                <a
                  className="btn btn-common mt-20 w-100 d-block login-btn"
                  onClick={this.saveLogin.bind(this)}>
                  Login
                </a>
              </div>
              <div className="text-center" style={{ marginTop: "10px" }}>
                {/* <img
                              src={borderimg}
                              style={{ width: isMobile ? "100%" : "auto" }}
                            /> */}
                <div
                  className="newTowebsite-register"
                  style={{ display: "flex", justifyContent: "center" }}>
                  <p>New to {this.context.clientData[0]?.store}?</p>
                  <p
                    style={{
                      marginLeft: "5px",
                      cursor: "pointer",
                      color: "#221f1f",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      this.showFormChange();
                    }}>
                    Register Now!
                  </p>
                </div>
              </div>
              {/* <div className="but">
                                                        <button type="button" className="btn btn-secondary" onClick={() => {
                                                            window.location.href = "/register"
                                                        }}>
                                                            Create your GreensWorth account
                                                        </button>
                                                    </div> */}
            </div>
          )}
          {!this.state.formChange && (
            <div class="login-box pb-0">
              <div className="section-title mb-0 pb-3 mt-0">
                <div className="login-modal-icon">
                  <BsFillPersonPlusFill />
                </div>
                <h4 className="section-title-category-section">
                  New to {this.context.clientData[0]?.store}?
                </h4>
              </div>
              <p
                style={{
                  textAlign: "center",
                  marginTop: "-5px",
                  marginBottom: "30px",
                }}>
                Shop with us & get attractive deals
              </p>

              <div class="row">
                <div class="col-md-12">
                  <input
                    type="text"
                    placeholder="Name"
                    class="form-control"
                    value={this.state.Name}
                    onChange={(name) => {
                      this.setState({
                        Name: name.target.value,
                      });
                    }}></input>
                </div>
                <div class="col-md-12" style={{ marginTop: "10px" }}>
                  <input
                    type="text"
                    placeholder="Email ID"
                    class="form-control"
                    value={this.state.EmailAddress}
                    onChange={(email) => {
                      this.setState({
                        EmailAddress: email.target.value,
                      });
                    }}></input>
                </div>
                <div class="col-md-12" style={{ marginTop: "10px" }}>
                  <input
                    type="text"
                    placeholder="Mobile Number"
                    class="form-control"
                    value={this.state.MobileNo}
                    onChange={(mobile) => {
                      if (
                        this.state.NumRegex.test(mobile.target.value) &&
                        mobile.target.value.length <= 10
                      ) {
                        this.setState({
                          MobileNo: mobile.target.value,
                        });
                      }
                    }}
                  />
                </div>
                <div
                  class="col-md-12"
                  style={{ marginTop: "10px", position: "relative" }}>
                  <input
                    placeholder="Password"
                    class="form-control"
                    type={this.state.isNewPasswordVisible ? "text" : "password"}
                    value={this.state.RegisterPassword}
                    onChange={(pswd) => {
                      this.setState({
                        RegisterPassword: pswd.target.value,
                      });
                    }}
                  />
                  <AiFillEyeInvisible
                    role={"button"}
                    onClick={() =>
                      this.setState({
                        isNewPasswordVisible: !this.state.isNewPasswordVisible,
                      })
                    }
                    style={{
                      position: "absolute",
                      right: "5%",
                      bottom: "36%",
                    }}
                  />
                </div>

                <div className="col-md-12" style={{ marginTop: "10px" }}>
                  <select
                    className="form-control"
                    value={this.state.Gender}
                    onChange={(text) => {
                      this.setState({
                        Gender: text.target.value,
                      });
                    }}>
                    <option value=""> Gender</option>
                    {this.state.GenderData.map((data, i) => (
                      <option value={data.value} key={data.value}>
                        {data.label}
                      </option>
                    ))}
                  </select>
                </div>

                {/* <div class="col-md-12">
                  <label>Date of Birth</label>
                </div>

                <div class="col-md-3 mb-2 mb-lg-0">
                  <select
                    class="form-control registration-select-box"
                    value={this.state.DobDay}
                    onChange={(text) => {
                      this.setState({
                        DobDay: text.target.value,
                      });
                    }}>
                    {this.state.Day.map((spec) => (
                      <option key={spec.value} value={spec.label}>
                        {spec.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div class="col-md-5 mb-2 mb-lg-0">
                  <select
                    class="form-control registration-select-box"
                    value={this.state.DobMonth}
                    onChange={(text) => {
                      this.setState({
                        DobMonth: text.target.value,
                      });
                    }}>
                    {this.state.Month.map((spec) => (
                      <option key={spec.value} value={spec.label}>
                        {spec.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div class="col-md-4 mb-2 mb-lg-0">
                  <select
                    class="form-control registration-select-box"
                    value={this.state.DobYear}
                    onChange={(text) => {
                      this.setState({
                        DobYear: text.target.value,
                      });
                    }}>
                    {this.state.Year.map((spec) => (
                      <option key={spec.value} value={spec.label}>
                        {spec.label}
                      </option>
                    ))}
                  </select>
                </div> */}
                {/* </div> */}

                <div class="col-md-12 d-flex align-items-md-center mt-4">
                  <input type="checkbox" />
                  <label className="mt-0 mb-0 ml-1 mt-1">
                    By Registering, you agree to our <a href="/terms-conditions">Terms & Conditions</a>.
                  </label>
                </div>
                <div class="col-md-12 mb-3">
                  <a
                    onClick={this.SaveRegistration.bind(this)}
                    class="btn btn-common ">
                    Register
                  </a>
                </div>

                {/* <p className="desclam">
                            By Registering, you agree to our Terms & Conditions.
                          </p> */}
                <div className="col-12 text-center mt-1">
                  {/* <img
                              src={borderimg}
                              style={{ width: isMobile ? "100%" : "auto" }}
                            /> */}
                  <div
                    className="newTowebsite-register"
                    style={{ display: "flex", justifyContent: "center" }}>
                    <p>Already an user?</p>
                    <p
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                        color: "#221f1f",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        this.hideFormChange();
                      }}>
                      Login!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  }
}
