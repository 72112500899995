import { isMobile } from "react-device-detect";
import styled from "styled-components";

export const Heading = styled.h1`
  text-align: center;
  color: #221f1f;
`;

export const Content = styled.div`
  overflowy: scroll;
  height: 2500px;
`;

export const Button = styled.div`
  position: fixed;
  width: 100%;
  left: ${isMobile ? "80%" : "94.6%"};
  bottom: ${isMobile ? "140px" : "125px"};
  height: 20px;
  font-size: ${isMobile ? "2.5rem" : "3rem"};
  z-index: 1;
  cursor: pointer;
  color: #221f1f;
  transition: transform 400ms ease;
`;
