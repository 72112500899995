import React, { Component } from "react";
import Footer from "./Footer";
import Header from "./Header";
import moment from "moment";
import Notiflix from "notiflix";
import PostApiCall from "./Api";
import login from "./assets/img/banner/login.png";
import borderimg from "./assets/img/banner/border.png";
import { isMobile } from "react-device-detect";
import { AiFillEyeInvisible } from "react-icons/ai";
import { store } from "./context/store";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IoPerson } from "react-icons/io5";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { FaLockOpen } from "react-icons/fa";

export default class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NewPassword: "",
      ConfirmNewPassword: ""
    };
  }

  render() {

    return (

      <div className="login-box pb-0">
        <div className="change-pass-icon">
          <FaLockOpen />
        </div>
        <div className="section-title mb-4 pb-2 mt-0">
          <h4 className="section-title-category-section">
            Change Password
          </h4>
        </div>
        <div>
          <input
            type="text"
            placeholder="New Password"
            className="form-control mb-3"
            value={this.state.NewPassword}
            onChange={(password) => {
              this.setState({
                NewPassword: password.target.value
              })
            }}
          />
        </div>
        <div>
          <input
            type="text"
            placeholder="Confirm Password"
            className="form-control"
            value={this.state.ConfirmNewPassword}
            onChange={(password) => {
              this.setState({
                ConfirmNewPassword: password.target.value
              })
            }}
          ></input>
        </div>

        <div>
          <div className="btn btn-common mt-20 w-100 d-block login-btn"
            onClick={() => {
              if (this.state.NewPassword != "") {
                if (this.state.ConfirmNewPassword != "") {
                  if (this.state.NewPassword == this.state.ConfirmNewPassword) {
                    Notiflix.Loading.Dots('');
                    var login = localStorage.getItem('CustomerData');
                    var details = JSON.parse(login)
                    PostApiCall.postRequest({
                      userid: details[0].fld_customerid,
                      password: this.state.ConfirmNewPassword,
                      salt: details[0].fld_salt,
                    }, "ChangeCustomerPassword").then((results) =>
                      // const objs = JSON.parse(result._bodyText)
                      results.json().then(obj => {
                        if (results.status == 200 || results.status == 201) {
                          Notiflix.Loading.Remove()
                          Notiflix.Notify.Success('Password Successfully updated')
                          localStorage.removeItem('CustomerData')
                          window.location.href = '/'
                        }
                        else {
                          Notiflix.Loading.Remove()
                          Notiflix.Notify.Failure('something went wrong, try again later')
                        }
                      }
                      )
                    )
                  } else {
                    Notiflix.Notify.Failure("New Password and Confirm New Password does not match")
                  }
                } else {
                  Notiflix.Notify.Failure("Confirm New Password can not be empty")
                }
              } else {
                Notiflix.Notify.Failure("New Password can not be empty")
              }
            }}>
            Change Password
          </div>
        </div>
      </div>

    );
  }
}
