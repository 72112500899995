import { isMobile } from 'react-device-detect';
import React from "react";
import Webheader from './Webheader';
import Headermobile from './Headermobile';

class DeviveHeader extends React.Component {
  renderContent = () => {
    if (isMobile) {
      return <div><Headermobile /> </div>
    }
    return <div> <Webheader /> </div>
  }

  render() {
    return (

      this.renderContent()
    )
  }

}
export default DeviveHeader;