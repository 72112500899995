import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import PostApiCall from "./Api";
import { store } from "./context/store";
import LoginModal from "./LoginModal";
// import GetApiCall from "./GetApi";
// import Slider from "react-slick";
import { FaCartPlus } from "react-icons/fa";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import NonVegIcon from "./assets/img/food-type/non-veg-icon.png";
import VegIcon from "./assets/img/food-type/veg-icon.png";
import VeganIcon from "./assets/img/food-type/vegan-icon.png";
import PincodeModal from "./PincodeModal/PincodeModal";

class BestSelling extends Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      VariantImage: null,
      VariantId: null,
      ProductId: null,
      VariantName: null,
      ItemName: null,
      VariantList: null,
      MRP: null,
      DiscountPrice: null,
      YouSave: null,
      SavePer: null,
      Stock: null,
      Weight: null,
      CategoryName: null,
      openModal: false,
      pincodeModal: false,
      itemType: "",
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });

    const store = this.context;

    try {
      // console.log(this.props.itemdata)
      //console.log(this.props.itemdata.fld_variantname)
      this.setState({
        itemType: this.props.itemdata.item_type,
        ItemName: this.props.itemdata.fld_variantname,
        VariantList: this.props.itemdata.variant_list,
        MRP: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[2],
        DiscountPrice: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[3],
        SavePer: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[4],
        YouSave: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[5],
        Stock: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[6],
        VariantId: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[1],
        VariantName: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[0],
        Weight: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[8],
        CategoryName: this.props.itemdata.fld_categoryname,

        VariantImage: this.props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[7]
          .split("~")[0],
        ProductId: this.props.itemdata.fld_productid,
      });
    } catch {
      try {
        this.setState({
          ItemName: this.props.itemdata.fld_variantname,
          VariantList: this.props.itemdata.variant_list,
          MRP: this.props.itemdata.variant_list.split("$")[1].split(",")[2],
          DiscountPrice: this.props.itemdata.variant_list
            .split("$")[1]
            .split(",")[3],
          SavePer: this.props.itemdata.variant_list.split("$")[1].split(",")[4],
          YouSave: this.props.itemdata.variant_list.split("$")[1].split(",")[5],
          Stock: this.props.itemdata.variant_list.split("$")[1].split(",")[6],
          VariantId: this.props.itemdata.variant_list
            .split("$")[1]
            .split(",")[1],
          VariantName: this.props.itemdata.variant_list
            .split("$")[1]
            .split(",")[0],
          Weight: this.props.itemdata.variant_list.split("$")[1].split(",")[8],
          CategoryName: this.props.itemdata.fld_categoryname,

          VariantImage: this.props.itemdata.variant_list
            .split("$")[1]
            .split(",")[7]
            .split("~")[0],
          ProductId: this.props.itemdata.fld_productid,
        });
      } catch { }
    }

    //console.log(this.state.VariantList.split("#"))
  }

  getCartdetails = () => {
    Notiflix.Loading.Circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    // console.log('cart')
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data) {
          Notiflix.Loading.Remove();

          localStorage.setItem("CardCount", JSON.stringify(res1.data.length));

          this.setState({ ProductCart: res1.data });
          this.context.setcart(res1.data.length);
          this.context.setcartamount(res1.data[0].fld_cart_amount_preview);
        }
      });
  };

  handleToggleModal = (updatedVal) => {
    this.setState({
      openModal: updatedVal,
    });
  };
  showPincodeModal = () => {
    this.setState({
      pincodeModal: true,
    });
  };
  closePincodeModal = () => {
    this.setState({
      pincodeModal: false,
    });
  };

  render() {
    const productslider = {
      dots: false,
      infinite: false,
      arrows: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    let imageSrc;
    switch (this.state.itemType) {
      case 'Veg':
        imageSrc = VegIcon;
        break;
      case 'Non Veg':
        imageSrc = NonVegIcon;
        break;
      case 'Contains Egg':
        imageSrc = VeganIcon;
        break;
      default:
        imageSrc = '';
    }

    //console.log(this.state.VariantName)

    return (
      <React.Fragment>
        <LoginModal
          isopen={this.state.openModal}
          handleToggleModal={this.handleToggleModal}
        />
        <div className="item">
          <div className="product-box">
            {/* <a href={`${process.env.PUBLIC_URL}/productdetail/${this.state.VariantId}/${this.state.ProductId}`}
                            className="action quickview" data-bs-toggle="modal" data-bs-target="#exampleModalCenter ">
                            <i className="pe-7s-search"></i></a> */}

            <div className="p-images blog-images position-relative">
              <a
                href={`${process.env.PUBLIC_URL
                  }/productdetail/${this.props.itemdata.fld_variantname.replace(
                    /\s/g,
                    "-"
                  )}/${this.state.VariantId}`}>
                <img
                  src={this.state.VariantImage}
                  alt={this.props.itemdata.fld_variantname.replace(
                    /\s/g,
                    "-"
                  )}></img>
                {this.props.itemdata.fld_discount_percent > 0 ?
                  <span className="offer-label">{this.props.itemdata.fld_discount_percent}%</span>
                  : ""}
                <span
                  className={this.state.itemType == null ? "d-none" : "veg-nonveg-icon"}>
                  <img src={imageSrc} alt={this.state.itemType} />
                </span>
              </a>
            </div>

            <div className="content-box position-relative">
              {/* <a
                href={`${
                  process.env.PUBLIC_URL
                }/productdetail/${this.props.itemdata.fld_variantname.replace(
                  /\s/g,
                  "-"
                )}/${this.state.VariantId}`}> */}
              <span style={{ color: "#00000075" }}>
                {this.state.CategoryName}
              </span>

              <p className="product-title2">
                <a
                  href={`${process.env.PUBLIC_URL
                    }/productdetail/${this.props.itemdata.fld_variantname.replace(
                      /\s/g,
                      "-"
                    )}/${this.state.VariantId}`}>
                  {this.state.VariantName?.split("(")[0]}
                  {this.state.VariantName?.split("(")[1] ? "(" : ""}
                  {this.state.VariantName?.split("(")[1]}
                </a>
              </p>
              {/* <a
                href={`${
                  process.env.PUBLIC_URL
                }/productdetail/${this.props.itemdata.fld_variantname.replace(
                  /\s/g,
                  "-"
                )}/${this.state.VariantId}`}> */}
              {/* <p className="price">
                <i className="fas fa-rupee-sign"></i> ₹{" "}
                {parseFloat(this.state.DiscountPrice).toFixed(2)}
                <span
                  style={{
                    display:
                      this.state.DiscountPrice == this.state.MRP
                        ? "none"
                        : "flex",
                  }}>
                  <i className="fas fa-rupee-sign"></i>{" "}
                  <s> ₹ {parseFloat(this.state.MRP).toFixed(2)}</s>
                  <br></br>
                  <em style={{ color: "#3e3e3e" }}>
                    You Save: ₹ {parseFloat(this.state.YouSave).toFixed(2)} (
                    {parseFloat(this.state.SavePer).toFixed(2)}%)
                  </em>
                </span>
              </p> */}
              <p className="price">
                <i className="fa fa-inr mr-2"></i>
                {parseFloat(this.state.DiscountPrice).toFixed(2)}
                {/* <span
                    style={{
                      display:
                        this.state.DiscountPrice == this.state.MRP
                          ? "none"
                          : "flex",
                    }}>
                    <i className="fas fa-rupee-sign"></i>{" "}
                    <s> ₹ {parseFloat(this.state.MRP).toFixed(2)}</s>
                    <br></br>
                    <em style={{ color: "#3e3e3e" }}>
                      You Save: ₹ {parseFloat(this.state.YouSave).toFixed(2)} (
                      {parseFloat(this.state.SavePer).toFixed(2)}%)
                    </em>
                  </span> */}
              </p>
              {this.context.clientData[0]?.storeStatus == "Active" &&
                <div className="add-to-cart-btn">
                  <div>
                    <a
                      title="Add to cart"
                      className="thm-btn itm-btns p-0"
                      style={{ cursor: "pointer", textAlign: "center" }}
                      onClick={() => {
                        // console.log('Cart')
                        // Notiflix.Loading.Circle();
                        var login = localStorage.getItem("CustomerData");
                        var logindetails = JSON.parse(login);

                        var Order = localStorage.getItem("OrderData");
                        var Orderdetails = JSON.parse(Order);
                        if (login == null) {
                          localStorage.setItem(
                            "itemdetails",
                            JSON.stringify([
                              {
                                Item: `${this.props.itemdata.fld_variantid}`,
                                Quantity: `${1}`,
                              },
                            ])
                          );
                          // window.location.href = "/login";
                          this.setState({
                            openModal: true,
                          });
                        } else {
                          if (
                            localStorage.getItem("Pincode") === null ||
                            localStorage.getItem("Pincode") === ""
                          ) {
                            this.showPincodeModal();
                          } else {
                            // ===========with Login=========
                            PostApiCall.postRequest(
                              {
                                orderdate: moment().format("lll"),
                                itemdetails: `
                                          [
                                          {
                                              "Item":${this.state.VariantId},
                                              "Quantity":${1}
                                          }
                                          ]
                                          `,
                                status: "INCART",
                                customerid:
                                  localStorage.getItem("CustomerData") == null
                                    ? null
                                    : logindetails[0].fld_customerid,
                                createdon: moment().format("lll"),
                                updatedon: moment().format("lll"),
                                orderid:
                                  localStorage.getItem("OrderData") == null
                                    ? 0
                                    : Orderdetails[0].order_id,
                                updatedby:
                                  localStorage.getItem("CustomerData") == null
                                    ? null
                                    : logindetails[0].fld_userid,
                              },
                              "AddToCartWebsite"
                            ).then((result) =>
                              result.json().then((obj) => {
                                if (
                                  result.status == 200 ||
                                  result.status == 201
                                ) {
                                  Notiflix.Loading.Remove();
                                  Notiflix.Notify.Success(
                                    "Product added to Cart."
                                  );
                                  // console.log(obj.data)
                                  if (localStorage.getItem("OrderData") == null) {
                                    //console.log('datastore')
                                    localStorage.setItem(
                                      "OrderData",
                                      JSON.stringify(obj.data)
                                    );
                                    this.getCartdetails();
                                  }
                                  this.getCartdetails();
                                } else {
                                  Notiflix.Loading.Remove();
                                  Notiflix.Notify.failure(obj.data);
                                }
                              })
                            );
                          }
                        }
                      }}>
                      {/* <i className="bi bi-basket"></i> */}
                      <FaCartPlus />
                      {/* <i className="bi bi-plus"></i> */}
                    </a>
                  </div>
                </div>
              }
              {/* <div className="justify-content-between align-content-center product-card-bottom-btns">
                <div>
                  <a
                    href={`${process.env.PUBLIC_URL
                      }/productdetail/${this.props.itemdata.fld_variantname.replace(
                        /\s/g,
                        "-"
                      )}/${this.state.VariantId}`}
                    className="thm-btn itm-btns">
                    Details
                  </a>
                </div>
                <div>
                  <a
                    className="thm-btn itm-btns add-to-cart-btn rounded-circle"
                    style={{ cursor: "pointer", textAlign: "center" }}
                    onClick={() => {
                      // console.log('Cart')
                      Notiflix.Loading.Circle();
                      var login = localStorage.getItem("CustomerData");
                      var logindetails = JSON.parse(login);

                      var Order = localStorage.getItem("OrderData");
                      var Orderdetails = JSON.parse(Order);
                      if (login == null) {
                        localStorage.setItem(
                          "itemdetails",
                          JSON.stringify([
                            {
                              Item: `${this.props.itemdata.fld_variantid}`,
                              Quantity: `${1}`,
                            },
                          ])
                        );
                        window.location.href = "/login";
                      } else {
                        // ===========with Login=========
                        PostApiCall.postRequest(
                          {
                            orderdate: moment().format("lll"),
                            itemdetails: `
                                          [
                                          {
                                              "Item":${this.state.VariantId},
                                              "Quantity":${1}
                                          }
                                          ]
                                          `,
                            status: "INCART",
                            customerid:
                              localStorage.getItem("CustomerData") == null
                                ? null
                                : logindetails[0].fld_customerid,
                            createdon: moment().format("lll"),
                            updatedon: moment().format("lll"),
                            orderid:
                              localStorage.getItem("OrderData") == null
                                ? 0
                                : Orderdetails[0].order_id,
                            updatedby:
                              localStorage.getItem("CustomerData") == null
                                ? null
                                : logindetails[0].fld_userid,
                          },
                          "AddToCartWebsite"
                        ).then((result) =>
                          result.json().then((obj) => {
                            if (result.status == 200 || result.status == 201) {
                              Notiflix.Loading.Remove();
                              Notiflix.Notify.Success("Product added to Cart.");
                              // console.log(obj.data)
                              if (localStorage.getItem("OrderData") == null) {
                                //console.log('datastore')
                                localStorage.setItem(
                                  "OrderData",
                                  JSON.stringify(obj.data)
                                );
                                this.getCartdetails();
                              }
                              this.getCartdetails();
                            } else {
                              Notiflix.Loading.Remove();
                              Notiflix.Notify.failure(obj.data);
                            }
                          })
                        );
                      }
                    }}>

                    <i className="bi bi-plus"></i>
                  </a>
                </div>
              </div>
              </a>
              {/* <a
                href={`${process.env.PUBLIC_URL
                  }/productdetail/${this.props.itemdata.fld_variantname.replace(
                    /\s/g,
                    "-"
                  )}/${this.state.VariantId}`}
              >
                <h6 className="price">
                  <i className="fas fa-rupee-sign"></i> ₹{" "}
                  {parseFloat(this.state.DiscountPrice).toFixed(2)}
                  <span style={{ display: this.state.DiscountPrice == this.state.MRP ? "none" : "flex" }}>
                    <i className="fas fa-rupee-sign"></i>{" "}
                    <s> ₹ {parseFloat(this.state.MRP).toFixed(2)}</s>
                    <br></br>
                    <em style={{ color: "#3e3e3e" }}>
                      You Save: ₹ {parseFloat(this.state.YouSave).toFixed(2)} (
                      {parseFloat(this.state.SavePer).toFixed(2)}%)
                    </em>
                  </span>
                  <h6 className="weight" style={{ color: this.state.Stock == "Out of Stock" ? 'red' : 'green' }}>{this.state.Stock} </h6>
                </h6>
              </a> */}
            </div>
            {/* <div className="btns">
              {this.state.Stock == "Out of Stock" ? (
                <a
                  className="thm-btn itm-btns"
                  style={{
                    background: "#a8aba8",
                    cursor: "default",
                    textAlign: "center",
                  }}
                >
                  Sold Out
                </a>
              ) : (
                <a
                  className="thm-btn itm-btns"
                  style={{ cursor: "pointer", textAlign: "center" }}
                  onClick={() => {
                    // console.log('Cart')
                    Notiflix.Loading.Circle();
                    var login = localStorage.getItem("CustomerData");
                    var logindetails = JSON.parse(login);

                    var Order = localStorage.getItem("OrderData");
                    var Orderdetails = JSON.parse(Order);
                    if (login == null) {
                      localStorage.setItem("itemdetails", JSON.stringify([
                        {
                          "Item": `${this.props.itemdata.fld_variantid}`,
                          "Quantity": `${1}`
                        }
                      ]))
                      window.location.href = "/login"
                    } else {
                      // ===========with Login=========
                      PostApiCall.postRequest(
                        {
                          orderdate: moment().format("lll"),
                          itemdetails: `
                                          [
                                          {
                                              "Item":${this.state.VariantId},
                                              "Quantity":${1}
                                          }
                                          ]
                                          `,
                          status: "INCART",
                          customerid:
                            localStorage.getItem("CustomerData") == null
                              ? null
                              : logindetails[0].fld_customerid,
                          createdon: moment().format("lll"),
                          updatedon: moment().format("lll"),
                          orderid:
                            localStorage.getItem("OrderData") == null
                              ? 0
                              : Orderdetails[0].order_id,
                          updatedby:
                            localStorage.getItem("CustomerData") == null
                              ? null
                              : logindetails[0].fld_userid,
                        },
                        "AddToCartWebsite"
                      ).then((result) =>
                        result.json().then((obj) => {
                          if (result.status == 200 || result.status == 201) {
                            Notiflix.Loading.Remove();
                            Notiflix.Notify.Success("Product added to Cart.");
                            // console.log(obj.data)
                            if (localStorage.getItem("OrderData") == null) {
                              //console.log('datastore')
                              localStorage.setItem(
                                "OrderData",
                                JSON.stringify(obj.data)
                              );
                              this.getCartdetails();
                            }
                            this.getCartdetails();
                          } else {
                            Notiflix.Loading.Remove();
                            Notiflix.Notify.failure(obj.data);
                          }
                        })
                      );
                    }
                  }}
                >
                  <i className="bi bi-basket"></i> &nbsp; {isMobile ? 'Add' : 'Add to Cart'}
                </a>
              )}
            </div> */}
          </div>
        </div>
        <Modal
          isOpen={this.state.pincodeModal}
          centered
          className="pincode-modal">
          <ModalHeader
            className="pt-0 pr-3 pb-0"
            toggle={this.closePincodeModal}></ModalHeader>
          <ModalBody className="px-3 pt-0">
            <PincodeModal showModal={this.closePincodeModal} />
          </ModalBody>
        </Modal>
        {/* </div> */}
      </React.Fragment>
    );
  }
}

export default BestSelling;
