import moment from "moment";
import Notiflix from "notiflix";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { FaPlusCircle } from "react-icons/fa";
import { MdAddLocationAlt, MdDeleteForever, MdEdit } from "react-icons/md";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import PostApiCall from "../Api";
import Footer from "../Footer";
import Header from "../Header";
import CustomerFrequentlyBought from "./CustomerFrequentlyBought";
import CustomerOrderList from "./CustomerOrderList";
import maleAvatar from "../assets/img/avatar-icons/male-avatar.png"
import femaleAvatar from "../assets/img/avatar-icons/female-avatar.png"
import AddressBookForm from "./AddressBookForm";
import { Drawer } from "antd";
import rightBanner from "../assets/img/customer-banner.jpg"
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePreviewUrl:
        "http://demo.globaltrendz.online/maxxchange/images/User/Shivani-1.jpg",

      ImageApiUrl: "http://api.globaltrendz.online/AddImage",
      Status: "Active",
      ImageData: [],

      UnitofMeasurement: "",
      Price: 0,
      Points: 0,
      DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
      RawMaterialName: "",
      GenderData: [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Others", label: "Others" },
      ],
      Gender: "",
      CountryData: [],
      CityData: [],
      StateData: [],
      CountryId: 0,
      StateId: 0,
      CityId: 0,
      Name: "",
      Mobile: "",
      Email: "",
      DOB: "",
      AnniversaryDate: "",
      Designation: "",
      Department: "",
      UserType: "",
      Address: "",
      Country: "",
      State: "",
      City: "",
      Pincode: "",
      AadharCard: "",
      PANCard: "",
      DrivingLicenses: "",
      BankName: "",
      Branch: "",
      AccountNumber: "",
      IFSCCode: "",
      Salary: "",
      Password: "",
      ConfirmPassword: "",
      UserData: [
        { value: "Admin", label: "Admin" },
        { value: "Super Admin", label: "Super Admin" },
        { value: "Users", label: "Users" },
      ],
      // DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
      NumRegex: /^[0-9]*$/,
      AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      UrlRegex:
        /^(https:\/\/www\.|https:\/\/www\.|https:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      DepartmentData: [],
      AadharData: [],
      AadharUrl: "http://api.globaltrendz.online/AddPdf",
      PanData: [],
      PanUrl: "http://api.globaltrendz.online/AddPdf",
      DrivingData: [],
      DrivingUrl: "http://api.globaltrendz.online/AddPdf",
      DesignationData: [],
      BankData: [],
      isNewPasswordVisible: false,
      isConPasswordVisible: false,
      Details: [],
      Logintime: "",
      CustomerData: [],
      AddressData: [],
      OrderData: [],
      showMenu: false,
    };
  }
  hideMenu = () => {
    this.setState({ showMenu: false });
  };
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#221f1f",
    });

    //   this.props.setclearbrand()
    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);
    //console.log(CustomerDetails[0].fld_customerid)

    Notiflix.Loading.Circle();

    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customer_id=${CustomerDetails[0].fld_customerid}`,
      },
      "Get_customerData_by_id"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          //console.log(obj.data)
          this.setState(
            {
              CustomerData: obj.data,
            }
            // () => console.log(this.state.CustomerData, "Customer Data")
          );
          Notiflix.Loading.Remove();
        }
      })
    );

    // ===============Address Data=================

    this.GetCustomerAddress();
  }
  GetCustomerAddress() {
    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);
    Notiflix.Loading.Circle();
    PostApiCall.postRequest(
      {
        whereClause: `where fld_customerid=${CustomerDetails[0].fld_customerid}`,
      },
      "GetCustomerAddress"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            AddressData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }
  render() {
    //console.log(this.state.LoginDetail.fld_salt);
    return (
      <React.Fragment>
        <Header />

        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <div className="row">
              {/* <div className="col-md-6">

								<div>
								<ul className="breadcrumbs">
								<li><a href="https://bechofy.in/">Bechofy.in</a></li>
								<li><a href="/customer-management">Customer List</a></li>
								<li>Customer Profile</li>
								</ul>
								</div>

							</div>
							<div className="col-md-6">
								<img src="http://globaltrendz.com/assets/img/gt-logo.jpeg" alt="company logo" className="img-responsive companyLogo"></img>

							</div> */}
            </div>

            <Row className="mt-3">
              <Col xl="12">
                <Card className="overflow-hidden mt-5 mt-lg-0">
                  <Row>
                    <Col xs="12" className="section-title mt-1 mb-3">
                      <h4 className="section-title-category-section">
                        Customer Profile <div className="title-divider"></div>
                      </h4>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "15px" }}>
                    <Col md="6" lg="5" className="pb-4">
                      <Card className="overflow-hidden address-card website-card position-relative">
                        <div
                          className="globalTrendzEcomm-bg-soft-pink"
                          style={{ background: "#221f1f", color: "#fff" }}>
                          {this.state.CustomerData.map((data, i) => {
                            if (i === 0) {
                              return (
                                <Row>
                                  <Col xs="12">
                                    <div className="globalTrendzEcomm-text p-3">
                                      <p>
                                        <img src={maleAvatar} className="img-fluid mr-2" />
                                        <span style={{ fontWeight: "300" }}>
                                          {data.fld_name}
                                        </span>
                                      </p>
                                      {data.fld_created_on &&
                                        <p>
                                          Customer Since:{" "}
                                          <span style={{ fontWeight: "300" }}>
                                            {moment(data.fld_created_on).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </span>
                                        </p>
                                      }
                                    </div>
                                  </Col>
                                </Row>
                              );
                            }
                          })}
                        </div>

                        <CardBody
                          className="pt-6"
                          style={{ paddingTop: "2rem" }}>
                          <Row>
                            {/* <Col xs="6"> */}
                            {/* <p>Date of Birth</p> */}
                            {/* <p>Marital Status</p> */}
                            {/* <p>Date of Anniversary</p> */}
                            {/* <p>Mobile</p> */}
                            {/* <p>Email</p> */}
                            {/* <p>Address</p> */}

                            {/* </Col> */}

                            {this.state.CustomerData.map((data, i) => {
                              if (i === 0) {
                                return (
                                  <Col xs="12">
                                    <p>
                                      Date of Birth:{" "}
                                      <p style={{ float: "right" }}>
                                        {data.fld_dob == null
                                          ? "N/A"
                                          : moment(data.fld_dob).format(
                                            "DD/MM/YYYY"
                                          )}
                                      </p>
                                    </p>
                                    {/* <p>
                                      Marital Status:{" "}
                                      <p style={{ float: "right" }}>
                                        {data.fld_maritalstatus == null
                                          ? "N/A"
                                          : data.fld_maritalstatus}
                                      </p>
                                    </p> */}
                                    {/* <p>
                                      Date of Anniversary:{" "}
                                      <p style={{ float: "right" }}>
                                        {data.fld_doa == null
                                          ? "N/A"
                                          : moment(data.fld_doa).format(
                                              "DD/MM/YYYY"
                                            )}{" "}
                                      </p>
                                    </p> */}
                                    <p>
                                      Mobile:{" "}
                                      <p style={{ float: "right" }}>
                                        {data.fld_phone_number == null
                                          ? "N/A"
                                          : data.fld_phone_number}{" "}
                                      </p>
                                    </p>
                                    <p>
                                      Email:{" "}
                                      <p style={{ float: "right" }}>
                                        {data.fld_email_address == null
                                          ? "N/A"
                                          : data.fld_email_address}{" "}
                                      </p>
                                    </p>
                                    {/* <p>
                                      Address:
                                      <p style={{ float: "right" }}>
                                        {data.fld_address_1},
                                        {data.fld_address_2}, {data.CityName},{" "}
                                        {data.StateName} ,{data.CountryName}
                                      </p>
                                    </p> */}
                                  </Col>
                                );
                              }
                            })}
                          </Row>
                          {this.state.CustomerData.map((data, i) => {
                            if (i == 0) {
                              return (
                                <Row className="customer-detail-edit">
                                  <div className="col-12">
                                    <MdEdit
                                      onClick={() => {
                                        localStorage.setItem(
                                          "ProfileData",
                                          JSON.stringify(data)
                                        );
                                        window.location.href =
                                          "/account_profile";
                                      }}
                                    />

                                    {/* <img
                                      src={Editicon}
                                      alt="edit profile"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "ProfileData",
                                          JSON.stringify(data)
                                        );
                                        window.location.href =
                                          "/account_profile";
                                      }}
                                      ></img> */}
                                  </div>
                                </Row>
                              );
                            }
                          })}
                        </CardBody>
                      </Card>
                    </Col>

                    {/* <Col md="3">
                      <Card className="mini-stats-wid ">
                        <CardBody>
                          <Media>
                            <Media body>
                              <div className="col-md-12">
                                <h4 className="mb-0 productTitle">
                                  Order Summary
                                </h4> */}

                    {/* <div className="col-md-2">
																		<i className="fa fa-list-alt OrdersIcons" aria-hidden="true"></i>


																	</div> */}

                    {/* </div> */}
                    {/* </div>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Total Sales Value
                                {this.state.OrderData.map((data, i) => {
                                  if (i === 0) {
                                    return (
                                      <span className="text-muted font-weight-medium float-right globalTrendzEcomm-count2 d-flex justify-content-center align-items-center">
                                        {data.TotalSalesValue}
                                      </span>
                                    );
                                  }
                                })}
                              </p>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Order Value
                                {this.state.OrderData.map((data, i) => {
                                  if (i === 0) {
                                    return (
                                      <span className="text-muted font-weight-medium float-right globalTrendzEcomm-count2 d-flex justify-content-center align-items-center">
                                        {data.OrderValue}
                                      </span>
                                    );
                                  }
                                })}
                              </p>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Today's Orders
                                {this.state.OrderData.map((data, i) => {
                                  if (i === 0) {
                                    return (
                                      <span className="text-muted font-weight-medium float-right globalTrendzEcomm-count2 d-flex justify-content-center align-items-center">
                                        {data.Todays_order}
                                      </span>
                                    );
                                  }
                                })}
                              </p>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                Today's Order Value
                                {this.state.OrderData.map((data, i) => {
                                  if (i === 0) {
                                    return (
                                      <span className="text-muted font-weight-medium float-right globalTrendzEcomm-count2 d-flex justify-content-center align-items-center">
                                        {data.Todays_order_value}
                                      </span>
                                    );
                                  }
                                })}
                              </p>
                              <hr />
                              <p className="text-muted font-weight-medium">
                                This Month Order
                                {this.state.OrderData.map((data, i) => {
                                  if (i === 0) {
                                    return (
                                      <span className="text-muted font-weight-medium float-right globalTrendzEcomm-count2 d-flex justify-content-center align-items-center">
                                        {data.thisMonth_order}
                                      </span>
                                    );
                                  }
                                })}
                              </p>
                            </Media>
                          </Media>
                        </CardBody>
                      </Card>
                    </Col> */}

                    {/* <Col md="4">
                      <OrderGraph style={{ height: "383px" }} /> */}

                    {/* <ReactApexChart options={this.state.options} series={this.state.series} type="donut" width={450} /> */}
                    {/* </Col> */}
                    {/* </Row>
                  <Row> */}

                    {/* Address -book */}
                    <Col md="6" lg="7" className="d-none d-md-block pb-4">
                      <img
                        // src={isMobile ? CartBannerMobile : CartBanner}
                        src={rightBanner}
                        alt="inner-banner"
                        className="img-responsive h-100"
                      />
                    </Col>
                    <Col md="12">
                      <Card className="overflow-hidden">
                        <CardBody>
                          <CardTitle className="section-title mt-0 mb-4">
                            <h4 className="section-title-category-section">
                              Address Book
                              <div className="title-divider"></div>
                            </h4>
                          </CardTitle>
                          <Row>
                            {/* {console.log(this.state.AddressData)} */}
                            {this.state.AddressData.map((data, index) => {
                              return (
                                <>
                                  <div
                                    className="col-lg-4 col-md-6 px-lg-2 px-0 mb-3"
                                    style={{
                                      display:
                                        this.state.AddressData.length != 0
                                          ? "block"
                                          : "none",
                                    }}>
                                    <Card className="address-card website-card">
                                      <div className="address-content">
                                        <div className="d-flex p-3 border-bottom border-dark align-items-center justify-content-between">
                                          <p className="fw-bold text-capitalize">
                                            {data.fld_contact_name}
                                          </p>
                                          <div className=" address-icon-row customer-address-edit">
                                            <a
                                              className="address-icon"
                                              onClick={() => {
                                                Notiflix.Loading.Circle();
                                                const details = JSON.parse(
                                                  localStorage.getItem(
                                                    "CustomerData"
                                                  )
                                                );
                                                PostApiCall.postRequest(
                                                  {
                                                    CUSTOMERID:
                                                      details[0].fld_customerid,
                                                    addressid:
                                                      data.fld_address_id,
                                                    title:
                                                      data.fld_address_type,
                                                    name: data.fld_contact_name,
                                                    mobileNo:
                                                      data.fld_contact_number,
                                                    area: data.fld_address_2,
                                                    flatno: data.fld_address_1,
                                                    landmark: data.fld_landmark,
                                                    country: data.Country,
                                                    state: data.State,
                                                    city: data.City,
                                                    pincode: data.fld_pincode,
                                                    action: "DELETE",
                                                  },
                                                  "AddCustomerAddress"
                                                ).then((results) =>
                                                  results.json().then((obj) => {
                                                    if (
                                                      results.status == 200 ||
                                                      results.status == 201
                                                    ) {
                                                      Notiflix.Loading.Remove();

                                                      Notiflix.Notify.Success(
                                                        "Address Successfully Delete."
                                                      );
                                                      this.GetCustomerAddress();
                                                    }
                                                  })
                                                );
                                              }}
                                            >
                                              {/* <img
                                                  src={Editicon}
                                                  alt="edit icon"
                                                  style={{
                                                    width: "15%",
                                                    float: "right",
                                                    marginLeft: "10px",
                                                    cursor: "pointer",
                                                  }}></img> */}
                                              <MdDeleteForever />
                                            </a>
                                            <a
                                              className="address-icon"
                                              onClick={() => {
                                                localStorage.setItem(
                                                  "AddressData",
                                                  JSON.stringify(data)
                                                );
                                                window.location.href =
                                                  "/editaddress";
                                              }}
                                            >
                                              {/* <img
                                                  src={DeletIcon}
                                                  alt="delete icon"
                                                  style={{
                                                    width: "15%",
                                                    float: "right",
                                                    cursor: "pointer",
                                                  }}></img> */}
                                              <MdEdit />
                                            </a>
                                          </div>
                                        </div>
                                        <div className="p-3">
                                          <p>
                                            {data.fld_address_1},{" "}
                                            {data.fld_address_2}, <br />
                                            {data.CityName}, {data.StateName}{" "}
                                            <br /> {data.fld_pincode},{" "}
                                            {data.CountryName}
                                          </p>
                                          <p>{data.fld_landmark}</p>
                                          <p
                                            style={{
                                              display:
                                                data.fld_gstin == null
                                                  ? "none"
                                                  : "block",
                                            }}>
                                            GSTIN : {data.fld_gstin}
                                          </p>
                                          <p
                                            style={{
                                              display:
                                                data.fld_contact_number == null
                                                  ? "none"
                                                  : "block",
                                            }}>
                                            {/* Contact Number:{" "} */}
                                            {data.fld_contact_number}
                                          </p>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                </>
                              );
                            })}
                            <div className="col-lg-4 col-md-4 col-sm-2 px-lg-2 px-0 mb-3">
                              <div
                                className="d-flex align-items-center justify-content-center address-box-profile address-card"
                                style={{
                                  height: "100%",
                                  cursor: "pointer",
                                }} onClick={() => {
                                  // window.location.href =
                                  //   "/address_book_form";
                                  this.setState({ showMenu: true });
                                }}>
                                <div className="address-plus" >
                                  <a className="address-icon customer-add-address">
                                    {/* <img
                                          src={Plusicon}
                                          alt="plus icon"
                                          onClick={() => {
                                            window.location.href =
                                              "/address_book_form";
                                          }}
                                          style={{ width: "40%" }}></img> */}
                                    {/* <i className="fa fa-plus-circle"
																					style={{
																						fontSize: '60px',
																					}}

																				></i> */}
                                    <MdAddLocationAlt

                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  {/* --incart */}
                  {/* <Row>
                    <Col xl="12">
                      <CustomerCardHistory />
                    </Col>
                  </Row> */}

                  {/* ====in order */}
                  <Row>
                    <CustomerOrderList />
                  </Row>

                  {/* ==FRequently= */}
                  {/* --incart */}
                  <Row>
                    <Col xl="12">
                      <CustomerFrequentlyBought />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
        <Drawer
          width="500px"
          placement="right"
          onClose={this.hideMenu}
          open={this.state.showMenu}
          className="select-slots-drawer">
          <AddressBookForm />
        </Drawer>

      </React.Fragment>
    );
  }
}

export default ChangePassword;
