import { Skeleton } from "antd";
import moment from "moment";
import React from "react";
import { isMobile } from "react-device-detect";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import ClientSupport from "../src/AllBrandComponent/ClientSupport/ClientSupport.js";
import Call from "./assets/img/call.png";
import custsupt from "./assets/img/footer-top-icons/24-hours-support.png";
import easyReturn from "./assets/img/footer-top-icons/easy.png";
import freeDelivery from "./assets/img/footer-top-icons/free-delivery.png";
import securePay from "./assets/img/footer-top-icons/secure-payment.png";
import Mail from "./assets/img/mail.png";
import AmexIcon from "./assets/img/payment/amex.svg";
import BHIMUPIIcons from "./assets/img/payment/bhim.svg";
import FreechargeIcon from "./assets/img/payment/freecharge.svg";
import GPayIcon from "./assets/img/payment/gpay.svg";
import MastercardIcon from "./assets/img/payment/mastercard.svg";
import PaytmIcon from "./assets/img/payment/paytm.svg";
import PhonePayIcon from "./assets/img/payment/phonepe.svg";
import RupayIcon from "./assets/img/payment/rupay.svg";
import UPI from "./assets/img/payment/upi.svg";
import VisaIcon from "./assets/img/payment/visa.svg";
import { store } from "./context/store";
import GetApiCall from "./GetApi";
import GoToTop from "./GoToTop/GoToTop";
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronRight />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronLeft />
    </div>
  );
}
// import { PiPhoneCallFill } from "react-icons/pi";
class Footer extends React.Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      clientName: "",
      clientdetails: [],
      staticpages: [],
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.toggleVisible());

    GetApiCall.getRequest("GetSiteSettingsV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            clientLogo: JSON.parse(obj1.data[0].logo).logo[0].url,
            clientName: obj1.data[0].name,
            clientdetails: obj1.data,
          });
        }
      })
    );
    GetApiCall.getRequest("GetStaticPagesList").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            staticpages: obj1.data,
          });
        }
      })
    );
  }

  toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      this.setState({
        visible: true,
      });
    } else if (scrolled <= 300) {
      this.setState({
        visible: false,
      });
    }
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  render() {
    const productslider = {
      dots: false,
      // infinite: false,
      arrows: false,
      speed: 300,
      // autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 4,

      responsive: [
        {
          breakpoint: 1290,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            arrows: true,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
          },
        },
      ],
    };
    return (
      <>
        <div>
          <section className="footer-section ">
            <div className="container-fluid">
              {/* {isMobile ? ( */}
              <div className="row">
                <div className="col-12 arrow-section">
                  <Slider {...productslider}>
                    <div className="card footercart">
                      <div className="card-body footerCard ">
                        <img
                          src={custsupt}
                          alt="customer-support"
                          className=" img-fluid img-responsive "></img>
                        <p className="CustomerPara">
                          <b className="footercardtext"> Customer Support</b>
                          <br />
                          <span style={{ fontSize: "14px" }}>
                            Need assistance? Call us and our team will help you
                            out.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="card footercart">
                      <div className="card-body footerCard">
                        <img
                          src={securePay}
                          alt="Secure Payment"
                          className="img-fluid img-responsive"></img>
                        <p className="CustomerPara">
                          <b className="footercardtext">Secure Payment</b>
                          <br />
                          <span style={{ fontSize: "14px" }}>
                            Making online payments on our site to buy is Safe
                            and Secure.
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="card footercart">
                      <div className="card-body footerCard">
                        <img
                          src={freeDelivery}
                          alt="Free Shipping"
                          className="img-fluid img-responsive"></img>
                        <p className="CustomerPara">
                          <b className="footercardtext">Free Shipping</b>
                          <br />
                          <span style={{ fontSize: "14px" }}>
                            Shipping cost is free for selected value of orders
                            made.
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="card footercart">
                      <div className="card-body footerCard">
                        <img
                          src={easyReturn}
                          alt="easyReturn"
                          className="img-fluid img-responsive"></img>
                        <p className="CustomerPara">
                          <b className="footercardtext"> Easy Return</b>
                          <br />
                          <span style={{ fontSize: "14px" }}>
                            Return of products if damaged or spoilt is very
                            easy.
                          </span>
                        </p>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
              {/* ) : ( */}
              {/* <div className="row">
                  <div className="col-lg-3">
                    <div className="card footercart">
                      <div className="card-body footerCard ">
                        <img
                          src={custsupt}
                          alt="customer-support"
                          className=" img-fluid img-responsive "
                        ></img>
                        <p className="CustomerPara">
                          <b className="footercardtext"> Customer Support</b>
                          <br />
                          <span style={{  fontSize: "14px" }}>
                            Need assistance? Call us and our team will help you
                            out.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card footercart">
                      <div className="card-body footerCard">
                        <img
                          src={securePay}
                          alt="Secure Payment"
                          className="img-fluid img-responsive"
                        ></img>
                        <p className="CustomerPara">
                          <b className="footercardtext">Secure Payment</b>
                          <br />
                          <span style={{  fontSize: "14px" }}>
                            Making online payments on our site to buy is Safe
                            and Secure.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card footercart">
                      <div className="card-body footerCard">
                        <img
                          src={freeDelivery}
                          alt="Free Shipping"
                          className="img-fluid img-responsive"
                        ></img>
                        <p className="CustomerPara">
                          <b className="footercardtext">Free Shipping</b>
                          <br />
                          <span style={{  fontSize: "14px" }}>
                            Shipping cost is Free for selected value of orders
                            made.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="card footercart">
                      <div className="card-body footerCard">
                        <img
                          src={easyReturn}
                          alt="easyReturn"
                          className="img-fluid img-responsive"
                        ></img>
                        <p className="CustomerPara">
                          <b className="footercardtext">Easy Return</b>
                          <br />
                          <span style={{  fontSize: "14px" }}>
                            Return of products if damaged or spoilt is very
                            easy.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </section>

          <footer className="footer footer-bg">
            <ClientSupport />
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-2 col-md-6 add contact-list pr-0 pr-md-4 pr-lg-0">
                  {this.context.logo ? (
                    <img
                      src={this.context.logo}
                      className="footer-logo img-fluid img-responsive mt-2 mb-3"
                      alt="logo"
                      style={{ width: "130px" }}></img>
                  ) : (
                    <Skeleton.Node />
                  )}
                  <div className="mt-2">
                    <p
                      style={{
                        color: "#333",
                        fontSize: "14px",
                        marginBottom: "10px",
                      }}>
                      We offer high-quality foods and the best delivery service,
                      and the food market you can blindly trust.{" "}
                    </p>
                  </div>
                </div>

                <div className="col-lg-2 col-xl-1 col-md-3 follow-us-section">
                  <div>
                    <ul className="contact-list">
                      <b className="mb-2 pb-1 d-block" style={{ fontSize: "20px" }}>Information</b>
                      {this.state.staticpages
                        .filter((dat) => {
                          if (
                            dat.fld_status === "Active" &&
                            dat.fld_page_name == "About Us"
                          ) {
                            return dat;
                          }
                        })
                        .map((data) => {
                          return (
                            <li>
                              <Link
                                to={`${data.fld_route}`}>
                                {data.fld_page_name}
                              </Link>
                            </li>
                          );
                        })}
                      <li>
                        {/* <a href="/faqs">FAQ</a> */}
                      </li>

                      <li style={{ borderRight: "none" }}>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                  {/* <img src="/assets/img/payment.png" className="width-100 payment"></img> */}
                </div>
                <div className="col-lg-3 col-xl-2 col-md-3 pl-xl-5 follow-us-section">
                  <div>
                    <ul className="contact-list">
                      <b className="mb-2 pb-1 d-block" style={{ fontSize: "20px" }}>Helpful Links</b>
                      {this.state.staticpages
                        .filter((dat) => {
                          if (
                            dat.fld_status === "Active" &&
                            dat.fld_page_name !== "About Us"
                          ) {
                            return dat;
                          }
                        })
                        .map((data) => {
                          return (
                            <li>
                              <Link
                                to={`${data.fld_route}`}
                              >
                                {data.fld_page_name}
                              </Link>
                              {/* <a
                                href={`/`}>
                                {data.fld_page_name}
                              </a> */}
                            </li>
                          );
                        })}

                      {/* <li>
                        <a href="/termsandcondition">Terms & Conditions</a>
                      </li>
                      <li>
                        <a href="/privacypolicy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/returnandrefundpolicy">
                          Return and Shipping Policy
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>

                <div className="col-lg-5 col-xl-4 col-md-6 pl-xl-5 follow-us-section">
                  <div>
                    <ul className="contact-list">
                      <p className="mb-2 pb-1"
                        style={{
                          color: "#212529",
                          fontSize: "20px",
                          fontWeight: "500",
                          // marginBottom: "10px",
                        }}>
                        <b>Let's Stay Connected</b>
                      </p>
                    </ul>
                    <div className="footerfollowus d-flex flex-column">
                      {/* <p
                        style={{
                          color: "333",
                          marginRight: "7px",
                          fontSize: "20px",
                          fontWeight: "500",
                          color: "#212529",
                        }}>
                        {" "}
                        Got a question ? Call us on{" "}
                      </p> */}
                      <a
                        href={`tel:${this.state.clientdetails[0]?.mobile}`}
                        className="headerContact footer-margin"
                        style={{

                          marginRight: "9px",
                          marginTop: "0px",
                          fontWeight: "400",
                          marginBottom: "5px",
                          // fontSize: "1.2rem",
                        }}>
                        <img src={Call} className="img-fluid mr-3" />

                        {this.state.clientdetails[0]?.mobile}
                      </a>
                      <a
                        href={`mailto:${this.state.clientdetails[0]?.email}`}
                        className="headerContact footer-margin"
                        style={{

                          marginRight: "9px",
                          marginTop: "0px",
                          fontWeight: "400",
                          // fontSize: "1.2rem",
                        }}>
                        <img src={Mail} className="img-fluid mr-3" />
                        {this.state.clientdetails[0]?.email}
                      </a>
                    </div>
                    <div className="footerfollowus mt-4">
                      <ul
                        className="social-icons footer-margin"
                        style={{ marginTop: "1px" }}>
                        {this.state.clientdetails[0]?.facebook ? (
                          <li>
                            <a
                              href={`${this.state.clientdetails[0]?.facebook}`}
                              target="_blank">
                              <i
                                className="fa fa-facebook-f social-icon"
                              ></i>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {this.state.clientdetails[0]?.instagram ? (
                          <li>
                            <a
                              href={`${this.state.clientdetails[0]?.instagram}`}
                              target="_blank">
                              <i
                                className="fa fa-instagram social-icon"
                              ></i>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {this.state.clientdetails[0]?.linkedin ? (
                          <li>
                            <a
                              href={`${this.state.clientdetails[0]?.linkedin}`}
                              target="_blank">
                              <i
                                className="fa fa-linkedin-square social-icon"
                              ></i>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {this.state.clientdetails[0]?.twitter ? (
                          <li>
                            <a
                              href={`${this.state.clientdetails[0]?.twitter}`}
                              target="_blank">
                              <i
                                className="fa fa-twitter-square social-icon"
                              ></i>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {this.state.clientdetails[0]?.pintrest ? (
                          <li>
                            <a
                              href={`${this.state.clientdetails[0]?.pintrest}`}
                              target="_blank">
                              <i
                                className="fa fa-pinterest-square social-icon"
                              ></i>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {this.state.clientdetails[0]?.youtube ? (
                          <li>
                            <a
                              href={`${this.state.clientdetails[0]?.youtube}`}
                              target="_blank">
                              <i
                                className="fa fa-youtube-square social-icon"
                                style={{ color: "#FF0000" }}></i>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 col-12 contact-list px-xl-0">
                  <b className="mb-2 pb-1 d-block" style={{ fontSize: "20px" }}>We Accept</b>
                  <ul
                    className="payment-icons list--inline site-footer__icon-list"
                  // style={{ float: "left" }}
                  >
                    <li className="payment-icon">
                      <img className="icon icon--full-color" src={VisaIcon} />
                    </li>

                    <li className="payment-icon">
                      <img
                        className="icon icon--full-color"
                        src={MastercardIcon}
                      />
                    </li>
                    <li className="payment-icon">
                      <img
                        className="icon icon--full-color"
                        src={AmexIcon}
                        style={{ backgroundColor: "#ffffff" }}
                      />
                    </li>
                    <li className="payment-icon">
                      <img
                        className="icon icon--full-color"
                        src={BHIMUPIIcons}></img>
                    </li>

                    <li className="payment-icon">
                      <img src={UPI} className="icon icon--full-color"></img>
                    </li>
                    <li className="payment-icon">
                      <img className="icon icon--full-color" src={RupayIcon} />
                    </li>
                    <li className="payment-icon">
                      <img className="icon icon--full-color" src={PaytmIcon} />
                    </li>
                    <li className="payment-icon">
                      <img
                        className="icon icon--full-color"
                        src={FreechargeIcon}
                      />
                    </li>
                    <li className="payment-icon">
                      <img
                        className="icon icon--full-color"
                        src={GPayIcon}></img>
                    </li>

                    <li className="payment-icon">
                      <img
                        src={PhonePayIcon}
                        className="icon icon--full-color"></img>
                    </li>
                  </ul>
                </div>
                {/* <div className="col-lg-4 col-md-3 follow-us-section">
                  <div>
                    <ul className="contact-list">
                      <p
                        style={{
                          color: "#212529",
                          fontSize: "20px",
                          fontWeight: "500",
                          marginBottom: "10px",
                        }}
                      >
                        We Accept
                      </p>

                      <ul
                        className="payment-icons list--inline site-footer__icon-list"
                        style={{ float: "left" }}
                      >
                        <li className="payment-icon">
                          <img className="icon icon--full-color" src={VisaIcon} />
                        </li>

                        <li className="payment-icon">
                          <img
                            className="icon icon--full-color"
                            src={MastercardIcon}
                          />
                        </li>
                        <li className="payment-icon">
                          <img
                            className="icon icon--full-color"
                            src={AmexIcon}
                            style={{ backgroundColor: "#ffffff" }}
                          />
                        </li>
                        <li className="payment-icon">
                          <img
                            className="icon icon--full-color"
                            src={BHIMUPIIcons}
                          ></img>
                        </li>

                        <li className="payment-icon">
                          <img src={UPI} className="icon icon--full-color"></img>
                        </li>
                        <li className="payment-icon">
                          <img className="icon icon--full-color" src={RupayIcon} />
                        </li>
                        <li className="payment-icon">
                          <img className="icon icon--full-color" src={PaytmIcon} />
                        </li>
                        <li className="payment-icon">
                          <img
                            className="icon icon--full-color"
                            src={FreechargeIcon}
                          />
                        </li>
                        <li className="payment-icon">
                          <img
                            className="icon icon--full-color"
                            src={GPayIcon}
                          ></img>
                        </li>



                        <li className="payment-icon">
                          <img
                            src={PhonePayIcon}
                            className="icon icon--full-color"
                          ></img>
                        </li>

                      </ul>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </footer >
          <div className="footer-bottom pt-3">
            {isMobile ? (
              <div className="row w-100">
                <div className="col-md-6 col-12 mt-lg-3">
                  <p className="copyrights">
                    © {moment().format("YYYY")} {this.state.clientName} All
                    Right Reserved.
                  </p>
                </div>
                <div className="col-md-6 col-12 mt-sm-3 mt-md-0 mt-2 mt-lg-3">
                  <p className="design footer-margin">
                    <a target="_blank" href="https://bechofy.in/">
                      <i>Powered by Bechofy </i>
                    </a>
                    - A Product of{" "}
                    <a href="http://globaltrendz.com/">Global Trendz</a>
                  </p>
                </div>

                {/* <a id="scroll-top" href="#top" title="Top" role="button">hii</a> */}
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="design footer-margin text-center text-lg-left">
                    <a target="_blank" href="https://bechofy.in/">
                      <i>Powered by Bechofy </i>
                    </a>
                    - A Product of{" "}
                    <a href="http://globaltrendz.com/" target="_blank">Global Trendz</a>
                  </div>
                </div>
                <div className="col-lg-6 col-12 text-center mt-0 mt-sm-3 mt-md-0 text-lg-right">
                  <div className="copyrights">
                    © {moment().format("YYYY")} {this.state.clientName} All
                    Right Reserved.
                  </div>
                </div>

                {/* <a id="scroll-top" href="#top" title="Top" role="button">hii</a> */}
              </div>
            )}
          </div>
        </div >
        <GoToTop />
      </>
    );
  }
}

export default Footer;
