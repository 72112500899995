import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import Notiflix from "notiflix";
import PostApiCall from "../Api";
import GetApiCall from "../GetApi";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";

// This component shows details of a single item from the order history 
export class ViewDetails extends Component {
  // initial states

  constructor(props) {
    super(props);
    this.state = {
      historyOrderData: [],
      returnStatus: false,
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });

    const { location } = this.props;
    const { itemId } = location.state || {}; // Destructure itemId from location.state, default to an empty object if location.state is undefined
    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);

    // function to get order details
    PostApiCall.postRequest(
      {
        // whereClause: `where fld_order_id = 46`,
        whereClause: `where fld_order_id = ${itemId}`,
      },
      "GetWebsiteOrderDetails"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            historyOrderData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }

  render() {
    return (
      <>
        <Header />
        <Container fluid className="pt-4 pt-md-0 mt-5 mt-lg-0 mb-5">
          <div className="row">
            <div className="section-title col-12 text-center">
              <h4 className="section-title-category-section mt-0">
                View Details
                {/* {itemId !== undefined ? `${itemId}` : "No item found"} */}
              </h4>
              <div className="title-divider"></div>
            </div>

            <div className="checkout-bx pb-0">
              {this.state.historyOrderData.length > 0 ? this.state.historyOrderData.map((product) => {
                return (
                  <>
                    <div className="position-relative">
                      <div className="row position-relative mb-4 py-3 pb-md-0 rounded w-100 m-0">
                        <div className="col-4 col-md-2">
                          <div className="row">
                            <div className="col-12">
                              <a
                              // href={`${process.env.PUBLIC_URL
                              //   }/productdetail/${product.fld_variantname.replace(
                              //     /\s/g,
                              //     "-"
                              //   )}/${product.fld_variantid
                              //   }`}
                              >
                                <img
                                  src={product.variant_image}
                                  // src=""
                                  alt="img"
                                  className="checkout-img mb-lg-3" />
                                {/* <span
                              className={
                                product.item_type ==
                                  null
                                  ? "d-none"
                                  : "veg-nonveg-icon"
                              }>
                              {this.getImage(
                                product.item_type
                              )}
                            </span> */}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 cproduct-name cproduct-card-name">
                          <span
                            className="d-block"
                            style={{
                              color: "#00000075",
                            }}>
                            {product.fld_categoryname}
                          </span>
                          <a
                            className="font-weight-bold"
                            href={`${process.env.PUBLIC_URL
                              }/productdetail/${product.variant_name.replace(
                                /\s/g,
                                "-"
                              )}/
                          `
                            }>
                            {product.variant_name}
                          </a>
                        </div>
                        {/* ${product.fld_variantid} */}

                        <div className="c-price">
                          <span className="d-flex mt-2">
                            <div className="prod-price mr-2 mb-0 pb-0">
                              ₹{" "}
                              {parseFloat(
                                product.fld_netvalue *
                                product.fld_quantity
                              ).toFixed(2)}
                            </div>
                            <s
                              style={{
                                color:
                                  "rgb(182, 193, 213)",
                              }}>
                              {" "}
                              ₹{" "}
                              {parseFloat(
                                product.fld_mrp *
                                product.fld_quantity
                              ).toFixed(2)}
                            </s>
                          </span>
                        </div>

                        <div className="mt-2 text-right">
                          <button
                            type="button "
                            className="btn align-items-center place-order w-auto p-2"
                            onClick={() => {
                            }}
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Return">
                            <span>
                              Return
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </>


                )
              }) : <p>No data found</p>}


            </div>
          </div>
        </Container>
        <Footer />
      </>
    );
  }
}

export default withRouter(ViewDetails);
