import React, { Component } from "react";

import { AES } from "crypto-js";
import Notiflix from "notiflix";
import { FaEye, FaFileInvoice } from "react-icons/fa";
import { Link } from "react-router-dom"; // to drill down the id of an item 
import { Card, CardBody, Col, Row } from "reactstrap";
import PostApiCall from "../Api";
import ViewInvoiceForm from "../ViewInvoiceForm";
// import InvoiceForm from "../InvoiceForm"
import {
  LoadingOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined,
  CreditCardOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Steps } from "antd";
class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderHistoryData: [], // show three orders by default
      searchInput: "",
      OpenModalForDownloadInvoice: false,
      idToFetch: "",
      visibleProducts: 3,
      paymentStatus: 'finish',
      dispatchStatus: 'wait'
    };
  }

  handleShowMore = () => {
    this.setState((prevState) => ({
      visibleProducts: prevState.visibleProducts + 3,
    }));
  };
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });

    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);

    // function to get order details
    PostApiCall.postRequest(
      {
        WhereClause: `where fld_customer_id=${parseInt(
          CustomerDetails[0].fld_customerid
        )}`,
        select: `distinct fld_delivery_challan,fld_order_number,fld_order_date,customer_name,total_items,fld_payment_status,fld_order_status,fld_dispatch_id,fld_order_amount,fld_dispatch_status,Total_Inclusive_Tax,fld_customerid  `,
      },
      "GetCustomerOrderDetails"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            orderHistoryData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };

  DownloadInvoicePdf() {
    var html2pdf = require("html2pdf.js");
    var element = document.getElementById("invoice");
    var opt = {
      margin: 1,
      filename: "invoice.pdf",
      image: { type: "png", quality: 0.98 },
      html2canvas: {
        scale: 1.1,
        useCORS: true,
        allowTaint: true,
        proxy: null,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      //   pagebreak: {avoid: ‘div’}
    };
    // ////console.log(opt)
    // New Promise-based usage:
    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => {
        Notiflix.Loading.Remove();
      });
  }
  toggleDownloadInvoice = (orderId) => {
    Notiflix.Loading.Circle();
    this.setState(
      {
        idToFetch: orderId,
      },
      () =>
        this.setState(
          {
            OpenModalForDownloadInvoice:
              !this.state.OpenModalForDownloadInvoice,
          }
          //console.log(this.state.idToFetch)
        )
    );
  };
  render() {
    const { visibleProducts } = this.state;
    const visibleProductList = this.state.orderHistoryData.slice(
      0,
      visibleProducts
    );
    const data = {
      columns: [
        {
          label: "S.No.",
          field: "serial",
          sort: "disabled",
          width: 75,
        },
        {
          label: "Order Number",
          field: "number",
          sort: "disabled",
          width: 600,
        },

        {
          label: "Order Date",
          field: "date",
          sort: "disabled",
          width: 600,
        },

        {
          label: "Total items",
          field: "item",
          sort: "disabled",
          width: 300,
        },

        {
          label: "Order Value",
          field: "ordervalue",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Order Status",
          field: "status",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Payment Status",
          field: "paystatus",
          sort: "disabled",
          width: 300,
        },
        {
          label: "Invoice",
          field: "invoice",
          sort: "disabled",
          width: 300,
        },
      ],
      rows: this.state.orderHistoryData
        .filter((data) => {
          if (this.state.searchInput == "") {
            return data;
          }
          if (
            this.state.searchInput !== "" &&
            data.fld_order_status
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase())
          ) {
            return data;
          }
          if (
            this.state.searchInput !== "" &&
            data.fld_order_number
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase())
          ) {
            return data;
          }
          if (
            this.state.searchInput !== "" &&
            data.fld_payment_status
              .toLowerCase()
              .includes(this.state.searchInput.toLowerCase())
          ) {
            return data;
          }
        })
        .map((data, i) => {
          return {
            serial: i + 1,
            number: data.fld_order_number,
            date: data.fld_order_date,

            item: data.total_items,
            ordervalue: data.fld_order_amount,
            status: data.fld_order_status,
            paystatus: data.fld_payment_status,
            invoice: (
              <div>
                {data.fld_order_status == "Delivered" ? (
                  <button
                    onClick={() =>
                      this.toggleDownloadInvoice(data.fld_dispatch_id)
                    }
                    className="active-button-order">
                    View Invoice
                  </button>
                ) : (
                  <button className="disabled-button-order">
                    View Invoice
                  </button>
                )}
              </div>
            ),
          };
        }),
    };

    return (
      <Col md="12">
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="section-title mt-0">
              {/* <CardTitle className="section-title-category-section mt-0 section-title usertitle"> */}
              {this.state.OpenModalForDownloadInvoice ? (
                <h4 className="section-title-category-section">
                  Download Invoice <div className="title-divider"></div>
                </h4>
              ) : (
                <h4 className="section-title-category-section mt-0">
                  Order History <div className="title-divider"></div>
                </h4>
              )}
              {/* </CardTitle> */}
            </div>
            {this.state.OpenModalForDownloadInvoice ? (
              <ViewInvoiceForm
                toogleBack={() =>
                  this.setState({
                    OpenModalForDownloadInvoice:
                      !this.state.OpenModalForDownloadInvoice,
                  })
                }
                IdToFetch={this.state.idToFetch}
              />
            ) : (
              <Row className="">
                {this.state.orderHistoryData.length != 0 ? (
                  visibleProductList
                    .filter((data) => {
                      if (this.state.searchInput == "") {
                        return data;
                      }
                      if (
                        this.state.searchInput !== "" &&
                        data.fld_order_status
                          .toLowerCase()
                          .includes(this.state.searchInput.toLowerCase())
                      ) {
                        return data;
                      }
                      if (
                        this.state.searchInput !== "" &&
                        data.fld_order_number
                          .toLowerCase()
                          .includes(this.state.searchInput.toLowerCase())
                      ) {
                        return data;
                      }
                      if (
                        this.state.searchInput !== "" &&
                        data.fld_payment_status
                          .toLowerCase()
                          .includes(this.state.searchInput.toLowerCase())
                      ) {
                        return data;
                      }
                    })
                    .map((data, i) => {
                      const id = data.fld_payment_trx_num;
                      const secretKey = "mel#4321!";
                      const encryptedID = AES.encrypt(
                        JSON.stringify(id.toString()),
                        secretKey
                      ).toString();
                      const urlSafeBase64 = encryptedID
                        .replace(/\//g, "-")
                        .toString()
                        .replace("?", "bechofy");
                      return (
                        // main cards for order history
                        <Col md="6" lg="4" className="mb-4 px-lg-2 px-0">
                          <div className="position-relative address-card website-card h-100 mb-0 border-dark">
                            <div className="border-bottom border-dark p-3">
                              <div>
                                <span className="font-weight-bold">
                                  Order #&nbsp;
                                </span>
                                <span className="font-weight-light">
                                  {data.fld_order_number}
                                </span>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="mb-2">
                                  <span className="font-weight-bold">
                                    Ordered On&nbsp;
                                  </span>
                                  {data.fld_order_date}
                                </div>
                                {/* <span className="order-status">
                                  {data.fld_order_status}
                                </span> */}
                              </div>
                            </div>
                            <div className="order-item-list p-3">
                              {/* <div className="row mb-2">
                                <div className="col-3">
                                  <img
                                    className="order-item-image"
                                    src={data.variantimage}
                                  />
                                </div>
                                <div className="col-6">
                                  <p>Item Name</p>
                                </div>
                                <div className="col-3">
                                  <p>0 Item</p>
                                </div>
                              </div> */}

                              <Steps
                                items={[
                                  {
                                    title: "Login",
                                    status: "finish",
                                    icon: <UserOutlined />,
                                  },
                                  {
                                    title: "Payment",
                                    status: data.fld_payment_status == "Successful" ? 'finish' : 'wait',
                                    icon: <CreditCardOutlined />,
                                  },
                                  {
                                    title: "Dispatched",
                                    status: data.fld_order_status == 'Dispatched' ? "finish" : "wait",
                                    icon: <CheckCircleOutlined />,
                                  },
                                ]}
                              />
                            </div>
                            {/* <div>{data.fld_payment_status}</div> */}
                            <hr className="mb-0 mt-2 border-dark" />
                            <div className="d-flex justify-content-between align-items-center p-3">
                              {/* {data.fld_order_status == "Delivered" ? ( */}
                              <div className="view-invoice-btn">
                                {/* <Link to={{ pathname: '/view-details', state: { itemId: data.fld_order_id } }} >
                                  <button disabled className="active-button-order w-auto"
                                  // onClick={() => { }}
                                  >
                                    View Details
                                  </button>
                                </Link> */}
                                <Link
                                  to={`view-invoice-form/${data.fld_order_id}/${urlSafeBase64}`}>
                                  <button
                                    // onClick={() =>
                                    //   this.toggleDownloadInvoice(
                                    //     data.fld_dispatch_id
                                    //   )
                                    // }
                                    className="active-button-order w-auto">
                                    Invoice
                                  </button>
                                </Link>
                              </div>
                              {/* ) : (
                                <button className="disabled-button-order w-auto">
                                  <FaFileInvoice />
                                </button>
                              )} */}
                              <div className="text-right">
                                <p className="font-weight-bold">
                                  ₹{data.fld_order_amount}
                                </p>
                                <p>{data.total_items} Item</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })
                ) : (
                  <p className="w-100 text-center">No Data Found</p>
                )}
                <Col xs="12" className="text-center">
                  {visibleProducts < this.state.orderHistoryData.length && (
                    <button
                      onClick={this.handleShowMore}
                      type="button"
                      className="btn align-items-center place-order w-auto">
                      Show More
                    </button>
                  )}
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default Chart;
